import { gql } from "@apollo/client";

export const GET_AUTH_USER_INFO = gql`
  query {
    me {
      id
      firstName
      lastName
      userName
      email
      isSuperuser
      householdSet {
        id
        name
      }
    }
    myHouseholdMemberships {
      user {
        id
        email
        userName
      }
      household {
        id
        name
      }
      accessLevel
    }
  }
`;
export const GET_SYSTEM_USERS = gql`
  query Users($search: String!, $pageCount: Int!, $pageNumber: Int!) {
    users(search: $search, pageCount: $pageCount, pageNumber: $pageNumber) {
      pageData {
        id
        firstName
        lastName
        userName
        phone
        email
        isActive
        isStaff
        profilePicture
        lastLogin
        createdAt
        householdSet {
          id
          name
          description
          family {
            accessLevel
            household {
              id
              name
            }
          }
        }
      }
      numPages
      totalCount
    }
  }
`;
export const WELCOME_QUERY = gql`
  query {
    welcome {
      message
    }
  }
`;
export const GET_HOUSEHOLDS = gql`
  query {
    households {
      id
      name
      description
      family {
        user {
          email
          userName
        }
      }
    }
  }
`;
