import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/styles";

import { TableToolbar } from "components";
import { UsersTable } from "./UsersTable";
import { useMutation, useQuery } from "@apollo/client";
import { GET_HOUSEHOLDS, GET_SYSTEM_USERS } from "graphql/queries";
import { userInitials } from "utils/initialStates";
import { AddUser } from "components/Models/AddUser";
import {
  REGISTER_USER_MUTATION,
  UPDATE_USER_MUTATION,
} from "graphql/mutations/user";
import { getUserHouseholds } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const initialState = {
  pageData: [],
  totalCount: 0,
  numPages: 0,
};
export const UserList = () => {
  const classes = useStyles();
  const [users, setUsers] = useState(initialState);
  const [newUser, setNewUser] = useState(userInitials);
  const [open, setOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  const [saveUser, { loading: saving, data: newData }] = useMutation(
    newUser.id ? UPDATE_USER_MUTATION : REGISTER_USER_MUTATION,
    {
      errorPolicy: "ignore",
    }
  );

  const { loading, data, refetch } = useQuery(GET_SYSTEM_USERS, {
    variables: { search, pageCount: rowsPerPage, pageNumber: page },
  });

  const { data: hData } = useQuery(GET_HOUSEHOLDS);
  useEffect(() => {
    if (data && data.users) {
      setUsers(data.users);
    }
  }, [data]);
  useEffect(() => {
    if (newData?.registerUser || newData?.updateRegisteredUser) {
      setNewUser(initialState);
      setOpen(false);
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);
  const handleSelectAll = (event) => {
    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.pageData.map((item) => item.id);
    } else {
      selectedUsers = [];
    }
    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = ({ target }) => {
    setPage(0);
    setRowsPerPage(target.value);
  };
  const handleSearchChange = ({ target }) => {
    setPage(0);
    setSearch(target.value);
  };

  const onSetEditUser = (currentUser) => {
    const { id, firstName, lastName, userName, email, phone, householdSet } =
      currentUser;
    const userHouseholds = getUserHouseholds(householdSet);
    setNewUser({
      id,
      firstName,
      lastName,
      userName,
      email,
      phone,
      configureHousehold: userHouseholds.length === 0,
      households: userHouseholds,
      password: "",
    });
    setOpen(true);
  };

  const households = useMemo(
    () => hData?.households?.map(({ id, name }) => ({ id, name })),
    [hData?.households]
  );
  return (
    <div className={classes.root}>
      <AddUser
        open={open}
        setOpen={() => setOpen(false)}
        values={newUser}
        setValues={setNewUser}
        onSave={() =>
          saveUser({
            variables: {
              ...newUser,
              ...(newUser.id && {
                households: newUser.households.map((hh) => ({
                  householdId: hh.household.id,
                  accessLevel: hh.accessLevel.id,
                })),
              }),
            },
          })
        }
        loading={saving}
        households={households || []}
      />
      <TableToolbar
        btnLabel="Add a new user"
        searchHolder="Search a user"
        onChange={handleSearchChange}
        onBtnClick={() => setOpen(true)}
      />
      <div className={classes.content}>
        <UsersTable
          users={users.pageData}
          usersCount={users.totalCount}
          loading={loading}
          selectedUsers={selectedUsers}
          rowsPerPage={rowsPerPage}
          page={page}
          onEdit={onSetEditUser}
          pageOptions={[5, 10, 25, 50, 100]}
          handleSelectOne={handleSelectOne}
          handleSelectAll={handleSelectAll}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    </div>
  );
};
