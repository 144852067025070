import moment from "moment";
import { toast } from "react-toastify";

/**
 *
 * @param {Int} total Overall
 * @param {Int} actual Actual value
 * @returns {Float} The Percentage
 */
export const calculePercent = (total = 0, actual = 0) => {
  let percent = 0;
  if (parseInt(total) > 0 && parseInt(actual) > 0) {
    percent = (parseInt(actual) * 100) / parseInt(total);
  }
  return percent.toFixed(2);
};

const timeDifference = (current, previous) => {
  const milliSecondsPerMinute = 60 * 1000;
  const milliSecondsPerHour = milliSecondsPerMinute * 60;
  const milliSecondsPerDay = milliSecondsPerHour * 24;
  const milliSecondsPerMonth = milliSecondsPerDay * 30;
  const milliSecondsPerYear = milliSecondsPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < milliSecondsPerMinute / 3) {
    return "just now";
  }

  if (elapsed < milliSecondsPerMinute) {
    return "less than 1 min ago";
  } else if (elapsed < milliSecondsPerHour) {
    return Math.round(elapsed / milliSecondsPerMinute) + " min ago";
  } else if (elapsed < milliSecondsPerDay) {
    return Math.round(elapsed / milliSecondsPerHour) + " h ago";
  } else if (elapsed < milliSecondsPerMonth) {
    return Math.round(elapsed / milliSecondsPerDay) + " days ago";
  } else if (elapsed < milliSecondsPerYear) {
    return Math.round(elapsed / milliSecondsPerMonth) + " mo ago";
  } else {
    return Math.round(elapsed / milliSecondsPerYear) + " years ago";
  }
};

export const toDate = (date) => {
  const now = new Date().getTime();
  const updated = new Date(date).getTime();
  if (date) return timeDifference(now, updated);
  else return "Never";
};
/**
 *
 * @param {array} selectedIds Selected Ids
 * @param {array} mixedData List of data
 * @param {boolean} isProperty
 * @returns {array} Data mapped to new model
 */
export const toStoreModel = (selectedIds = [], mixedData = []) => {
  let stores = [];
  for (const element of mixedData) {
    for (const currId of selectedIds) {
      if (element.id === currId) {
        stores.push({
          amount: element.amount,
          record_type: "use",
          is_property: element.propertyId ? true : false,
          property_id: element.propertyId,
          is_inflow: element.type === "in" ? true : false,
          action_date: element.date,
          description: element.details,
        });
      }
    }
  }
  return stores;
};
/**
 *
 * @param {Array} anArray The current array needs to be updated
 * @param {*} newItem Item to add if any
 */
export const modifyArray = (anArray, newItem) => {
  let theArray = [...anArray];
  theArray.pop();
  theArray.unshift(newItem);
  return theArray;
};
export const toCurrency = (amount) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "RWF",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  }).format(amount);

export const toServerDate = (date) => {
  if (date) {
    return moment(new Date(date)).format("YYYY-MM-DD");
  }
  return moment().format("YYYY-MM-DD");
};
export const initialStoreFilters = {
  pageNumber: 1,
  pageCount: 10,
  searchKey: "",
  searchDateFrom: moment().startOf("y").format("YYYY-MM-DD"),
  searchDateTo: moment().format("YYYY-MM-DD"),
};
export const initialBudgetFilters = {
  pageNumber: 1,
  pageCount: 10,
  searchKey: "",
  searchMember: "",
  searchStartDate: "",
  searchEndDate: "",
};
export const householdAccessLevels = [
  { id: 1, name: "Member Admin" },
  { id: 2, name: "Staff Admin" },
  { id: 3, name: "Member" },
];
export const getUserHouseholds = (households = []) => {
  const result = [];
  households.forEach((hh) => {
    const hhInFamily = hh.family?.find((hhf) => hhf.household?.id === hh.id);
    result.push({
      household: { id: hh.id, name: hh.name },
      accessLevel: householdAccessLevels.find(
        (hal) => `A_${hal.id}` === hhInFamily.accessLevel
      ),
    });
  });
  return result;
};

export const calculateTatal = (arr = [], key) => {
  return arr.reduce((pv, curr) => pv + curr[key] ?? curr, 0);
};

export const toGerund = (word) => {
  return word.replace(/e$/, "ing");
};

export const toDateF = (date = "") => {
  if (!date) return moment().format("YYYY-MM-DD");
  return moment(date).format("YYYY-MM-DD");
};

export const notifier = (message, type = "error", position = "bottom_right") =>
  toast(message, {
    type: toast.TYPE[type.toUpperCase()],
    position: toast.POSITION[position.toUpperCase()],
    toastId: 13,
  });

export const pluralize = (count = 0, single = "item", plural = "items") => {
  if (count === 0) return `No ${single}`;
  if (count === 1) return `Only 1 ${single}`;
  return `${count} ${plural}`;
};

export const toRequestObject = (objData = {}, keysNotTobeChange = []) => {
  const newObject = {};
  Object.entries(objData).forEach(([key, value]) => {
    if (typeof value === "object") {
      const newKey = keysNotTobeChange.includes(key) ? key : `${key}Id`;
      newObject[newKey] = value?.id;
    } else {
      newObject[key] = value;
    }
  });
  return newObject;
};

export const performRedirect = (path) => {
  window.location.replace(`/?redirectTo=${path}`);
};

export const redirectToHome = (defaultPath = "/dashboard") => {
  const searchParams = new URLSearchParams(window.location.search);
  const redirectTo = searchParams.get("redirectTo");
  let withParams = "";
  let url = defaultPath;
  if (searchParams.size && !redirectTo) {
    withParams = `?${searchParams.toString()}`;
  }
  if (redirectTo) {
    url = redirectTo;
  }

  window.location.replace(`${url}${withParams}`);
};

export const redirectToSignin = () => {
  const currentPath = window.location.pathname;
  if (currentPath !== "/" && currentPath !== "/sign-in") {
    localStorage.clear();
    window.location.replace(`/sign-in?redirectTo=${currentPath}`);
  }
};

export const getQParams = (search) => {
  const params = {};
  const qParams = new URLSearchParams(search);
  qParams.forEach((value, key) => {
    console.log({ value, key });
  });
  return params;
};
