import React from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Grid,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

export const AddProperty = ({
  open,
  setOpen,
  values = {},
  onHandleChange,
  onSave,
  loading = false,
}) => {
  return (
    <Dialog open={open} onClose={setOpen} aria-labelledby='form-dialog-title'>
      <DialogTitle id='add-dialog'>Add a propert</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To add a property, please fill all required details below.
        </DialogContentText>
        <Grid container spacing={3}>
          <Grid item md={7} xs={12}>
            <TextField
              autoFocus
              margin='dense'
              variant='outlined'
              label='Property name'
              fullWidth
              required
              name='name'
              value={values.name}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <TextField
              fullWidth
              label='Price'
              margin='dense'
              type='number'
              variant='outlined'
              name='price'
              required
              value={values.price}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name='isActive'
                  checked={values.isActive}
                  onChange={onHandleChange}
                />
              }
              label='Is still active?'
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              margin='dense'
              variant='outlined'
              label='Property description'
              fullWidth
              required
              name='description'
              value={values.description}
              onChange={onHandleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={setOpen} color='primary'>
          Cancel
        </Button>
        <Button onClick={onSave} color='primary' disabled={loading}>
          {loading ? 'Saving data,...' : 'Save property'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
