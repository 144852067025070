import { client } from "graphql/client";
import { GET_PROPERTIES_QUERY } from "graphql/queries";
import { useEffect, useState } from "react";

const initialGetProps = {
  properties: {
    pageData: [],
    totalCount: 0,
    numPages: 0,
  },
  propertiesDetail: {
    count: 0,
    totalAmount: 0,
  },
};

const useGetProducts = ({
  search = "",
  pageCount = 10,
  pageNumber = 1,
  isActive = "yes",
}) => {
  const [products, setProducs] = useState(initialGetProps);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    client
      .query({
        query: GET_PROPERTIES_QUERY,
        variables: { search, pageCount, pageNumber, isActive },
        errorPolicy: "all",
      })
      .then(({ data, loading, errors }) => {
        setIsLoading(() => loading);
        if (!errors && data) {
          setProducs(() => data);
        }
      })
      .catch(({ message }) => {
        setError(() => message);
      });
  }, [search, pageNumber, pageCount, isActive]);
  return [products, isLoading, error];
};

export default useGetProducts;
