import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { toast } from "react-toastify";
import { AUTH_TOKEN } from "utils/constants";

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    let errorMessage = "";
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
      errorMessage = graphQLErrors[0].message;
    } else if (networkError) {
      errorMessage = networkError.message;
      console.log(`[Network error]: ${networkError.stack}`);
    }
    // console.log('Error message', errorMessage);
    toast(errorMessage, {
      type: toast.TYPE.ERROR,
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: 13,
    });
  }
);

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_TOKEN);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});
