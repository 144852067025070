import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import FusionChart from "components/common/FusionChart";
import { storeGaugeOptions } from "utils/constants";
import { Loading } from "components";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  chartContainer: {
    position: "relative",
    height: "300px",
  },
  stats: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  device: {
    textAlign: "center",
    padding: theme.spacing(1),
  },
  deviceIcon: {
    color: theme.palette.icon,
  },
}));
export const StoreGaugeView = ({
  className,
  store = { storeAggregate: {} },
  loading = false,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const devices = [
    {
      title: "Low",
      value: "0-30",
      color: theme.palette.success.main,
    },
    {
      title: "Modelate",
      value: "31-70",
      color: theme.palette.warning.main,
    },
    {
      title: "High",
      value: "71>",
      color: theme.palette.error.main,
    },
  ];

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <IconButton size="small">
            <RefreshIcon />
          </IconButton>
        }
        title="Store view"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {loading ? (
            <Loading />
          ) : (
            <FusionChart
              type="angulargauge"
              chartOptions={storeGaugeOptions(store.storeAggregate?.percent)}
            />
          )}
        </div>
        <Typography variant="h4">Savings(balance) Vs Expenses(%)</Typography>
        <div className={classes.stats}>
          {devices.map((device) => (
            <div className={classes.device} key={device.title}>
              <Typography variant="body1">{device.title}</Typography>
              <Typography style={{ color: device.color }} variant="h3">
                {device.value}
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
