import React, { useEffect, useMemo, useState } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Grid,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { storeChoices } from "utils/constants";
import { useGetProducts } from "helpers";
import { useMutation } from "@apollo/client";
import { NumericFormat } from "react-number-format";
import { useRequestQuery } from "helpers/useRequest";
import { GET_BUDGET_QUERY, GET_RECURRING_STORES } from "graphql/queries";
import { STORE_MUTATION, UPDATE_STORE_MUTATION } from "graphql/mutations";
import { recordInitials } from "utils/initialStates";
import { D2DAutocomplete } from "components/common";
import { useAuth } from "providers/auth";
import { toRequestObject } from "utils/helpers";

export const CreateOrUpdateRecord = ({
  open,
  setOpen,
  refetch,
  currentRecord: thisRecord,
}) => {
  const { user } = useAuth();
  const [recordValues, setRecordValues] = useState(recordInitials);
  const [propSearchKey, setPropSearchKey] = useState("");
  const [currentRecord, setCurrentRecord] = useState(null);
  const [products, productFetching] = useGetProducts({
    isActive: "yes",
    search: propSearchKey,
  });
  const { results, loading: fetching } = useRequestQuery({
    query: GET_RECURRING_STORES,
    variables: { searchKey: recordValues.description },
    dataType: "recurringStores",
  });
  const { results: currentBudget } = useRequestQuery({
    query: GET_BUDGET_QUERY,
    variables: { budgetId: "" },
    dataType: "currentBudget",
  });

  const [saveStore, { loading: saving, data: savedData }] = useMutation(
    currentRecord?.id ? UPDATE_STORE_MUTATION : STORE_MUTATION
  );

  useEffect(() => {
    if (thisRecord) {
      setCurrentRecord(thisRecord);
      const theRecord = { ...thisRecord };
      theRecord.property = thisRecord?.property || recordInitials.property;
      theRecord.budgetItem =
        thisRecord?.budgetItem || recordInitials.budgetItem;
      theRecord.recordType = storeChoices.find(
        (rt) => rt.id === thisRecord.recordType.toLowerCase()
      );
      setRecordValues((prev) => ({ ...prev, ...theRecord }));
    }
  }, [thisRecord]);
  useEffect(() => {
    if (user) {
      setRecordValues((prev) => ({
        ...prev,
        household: user.householdSet?.[0],
      }));
    }
  }, [user]);
  useEffect(() => {
    if (savedData?.createStore || savedData?.updateStore) {
      setCurrentRecord(null);
      setRecordValues(({ household, actionDate }) => ({
        ...recordInitials,
        household,
        actionDate,
      }));
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedData]);
  const onHandleChange =
    (hasCheck = false) =>
    ({ target: { name, value, checked } }) => {
      setRecordValues((prev) => ({
        ...prev,
        [name]: hasCheck ? checked : value,
      }));
    };
  const handleACChange = (key) => (_, value) => {
    setRecordValues((prev) => ({ ...prev, [key]: value }));
  };
  const onSaveRecord = () => {
    const { amount, ...rest } = recordValues;
    const recordOptions = {
      variables: {
        ...toRequestObject(rest, ["recordType"]),
        amount: amount || 0,
      },
    };
    saveStore(recordOptions);
  };
  const recurringStores = useMemo(
    () => results?.pageData?.map((rs) => rs.name),
    [results?.pageData]
  );
  const { budgetItems } = currentBudget;
  return (
    <Dialog open={open} onClose={setOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="add-dialog">
        {recordValues.id ? "Update record" : "Add a record"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {recordValues.id
            ? "Update the record on "
            : "To add a record to your store, please fill all required details below."}
        </DialogContentText>
        <Grid container spacing={1}>
          <Grid item md={5} xs={12}>
            <D2DAutocomplete
              id="record-type"
              name="recordType"
              label="Record type"
              getOptionLabel={(op) => op.name}
              options={storeChoices || []}
              onChange={handleACChange("recordType")}
              value={recordValues.recordType}
            />
          </Grid>
          <Grid item md={7} xs={12}>
            <D2DAutocomplete
              id="property"
              name="property"
              label="Has product? Select one"
              getOptionLabel={(op) => op.name}
              options={products.properties?.pageData || []}
              onChange={handleACChange("property")}
              onInputChange={(_, value) => {
                setPropSearchKey(value);
              }}
              value={recordValues.property}
              isLoading={productFetching}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              type="date"
              name="actionDate"
              value={recordValues.actionDate}
              onChange={onHandleChange(false)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <NumericFormat
              value={recordValues.amount}
              onValueChange={({ floatValue }) =>
                setRecordValues((prev) => ({ ...prev, amount: floatValue }))
              }
              //   prefix="RF"
              margin="dense"
              thousandSeparator
              customInput={TextField}
              fullWidth
              variant="outlined"
              label="Amount(RwF)"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="isInflow"
                  checked={recordValues.isInflow}
                  onChange={onHandleChange(true)}
                />
              }
              label="Is income?"
            />
          </Grid>
          {budgetItems?.length && (
            <Grid item xs={12}>
              <D2DAutocomplete
                id="budget-item"
                name="budgetItem"
                label="Is In The budget?(Select the budget item)"
                getOptionLabel={(op) => op.name}
                options={budgetItems || []}
                onChange={(_, item) => {
                  if (item) {
                    setRecordValues((prev) => ({
                      ...prev,
                      description: item.name,
                    }));
                  }
                  handleACChange("budgetItem")(_, item);
                }}
                value={recordValues.budgetItem}
              />
            </Grid>
          )}
          <Grid item md={8} xs={12}>
            <D2DAutocomplete
              id="description"
              name="description"
              label="Record description"
              multiline={true}
              options={recurringStores ?? []}
              onInputChange={handleACChange("description")}
              value={recordValues.description}
              freeSolo
              isLoading={fetching}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="isRecurring"
                  disabled={!!recordValues.id}
                  checked={recordValues.isRecurring}
                  onChange={onHandleChange(true)}
                />
              }
              label="Recurring?"
            />
          </Grid>
          {user.householdSet?.length > 1 && (
            <Grid item xs={12}>
              <D2DAutocomplete
                id="household"
                name="household"
                label="Family"
                getOptionLabel={(op) => op.name}
                options={user.householdSet || []}
                onChange={handleACChange("household")}
                value={recordValues.household}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={setOpen} color="primary">
          Cancel
        </Button>
        <Button onClick={onSaveRecord} color="primary" disabled={saving}>
          {recordValues.id && !saving ? "Update record" : "Save record"}
          {saving && "Saving record,..."}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
