import React from "react";
import { GraphiQL } from "graphiql";
import { API_URL, AUTH_TOKEN, defaultQuery } from "utils/constants";
import { useAuth } from "providers/auth";
import { Redirect } from "react-router-dom";

const token = localStorage.getItem(AUTH_TOKEN);

export const APIConsole = () => {
  const { user } = useAuth();

  if (!user.isSuperuser) return <Redirect to="/" />;

  const fetcher = async (graphQLParameters) => {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: token ? `JWT ${token}` : "",
      },
      body: JSON.stringify(graphQLParameters),
      credentials: "same-origin",
    });
    return response.json().catch(() => response.text());
  };

  return (
    <GraphiQL fetcher={fetcher} defaultQuery={defaultQuery}>
      <GraphiQL.Logo>GraphQL API console</GraphiQL.Logo>
    </GraphiQL>
  );
};
