import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress,
} from "@material-ui/core";
import { StoreMallDirectory } from "@material-ui/icons";
import { calculePercent } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  progress: {
    marginTop: theme.spacing(3),
  },
}));

export const StoreDetail = ({
  className,
  totalInflow = 0,
  totalOutflow = 0,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              STORE DETAILED VIEW {calculePercent(totalInflow, totalOutflow)}
            </Typography>
            <Typography variant="h3">{totalInflow}</Typography>
            <Typography variant="h5">{totalOutflow}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <StoreMallDirectory className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <LinearProgress
          className={classes.progress}
          value={calculePercent(totalInflow, totalOutflow) | 0}
          variant="determinate"
        />
      </CardContent>
    </Card>
  );
};
