import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

export const D2DAutocomplete = ({
  id = "autocomplete-input",
  options = [],
  isLoading,
  label,
  name,
  multiline,
  minRows,
  ...autocompleteOptions
}) => {
  return (
    <Autocomplete
      id={id}
      options={options}
      //   getOptionLabel={(op) => op.name}
      {...autocompleteOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          multiline={multiline}
          minRows={multiline ? minRows || 3 : undefined}
          margin="dense"
          variant="outlined"
          label={label}
          fullWidth
          name={name}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
