import { gql } from "@apollo/client";

export const REGISTER_USER_MUTATION = gql`
  mutation RegisterUserMutation(
    $email: String!
    $phone: String!
    $userName: String!
    $password: String!
    $configureHousehold: Boolean!
  ) {
    registerUser(
      userName: $userName
      email: $email
      phone: $phone
      password: $password
      configureHousehold: $configureHousehold
    ) {
      message
      user {
        id
        email
        userName
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUserMutation(
    $id: String!
    $email: String!
    $phone: String!
    $userName: String!
    $password: String!
    $configureHousehold: Boolean!
  ) {
    updateRegisteredUser(
      id: $id
      userName: $userName
      email: $email
      phone: $phone
      password: $password
      configureHousehold: $configureHousehold
    ) {
      message
      user {
        id
        email
        userName
      }
    }
  }
`;
