import React, { useState, useMemo } from "react";
import { SearchInput, CustomisedTable } from "components/common";
import { Button, Chip, Grid, TextField, Typography } from "@material-ui/core";
import { transactionStyles } from "./transactionStyles";
import { transactionColumns } from "components/columns";
import { useQuery } from "@apollo/client";
import { GET_STORES_QUERY } from "graphql/queries";
import { CreateOrUpdateRecord } from "components/Models";
import { toCurrency } from "utils/helpers";
import { useStoreSearchParams } from "components/hooks/useStoreSearchParams";

const initialStates = {
  pageData: [],
  totalCount: 0,
  aggregate: {
    inflow: 0,
    outflow: 0,
    diff: 0,
  },
};

export const TransactionView = () => {
  const classes = transactionStyles();
  const [filters, setFilters] = useStoreSearchParams();

  const [currentRecord, setCurrentRecord] = useState(null);
  const [openAddRecord, setOpenAddRecord] = useState(false);

  const { loading, data, refetch } = useQuery(GET_STORES_QUERY, {
    variables: filters,
  });

  const handlePageChange = ({ selected }) => {
    setFilters({ pageNumber: selected + 1 });
  };

  const handleRowsPerPageChange = ({ target }) => {
    setFilters({ pageNumber: 1, pageCount: target.value });
  };
  const handleSearchChange = ({ target }) => {
    setFilters({ pageNumber: 1, [target.name]: target.value });
  };
  const onTransactionClick = (store) => {
    const theStore = { ...store };
    theStore.recordType = store.recordType.toLowerCase();
    setCurrentRecord(theStore);
    setOpenAddRecord(true);
  };
  const stores = useMemo(() => data?.stores || initialStates, [data]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {/* <StoreImport
          open={openImport}
          setOpen={() => setOpenImport(false)}
          assets={properties}
        /> */}
        {/* {renderComponent} */}
        <CreateOrUpdateRecord
          open={openAddRecord}
          setOpen={() => {
            setOpenAddRecord(false);
          }}
          currentRecord={currentRecord}
          refetch={refetch}
        />
        <span className={classes.spacer} />
        {/* <Button
          className={classes.importButton}
          onClick={() => setOpenImport(true)}
        >
          Import
        </Button> */}
        {/* <Button className={classes.exportButton}>Export</Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpenAddRecord(true)}
        >
          Add a new record
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search record"
          name="searchKey"
          value={filters.searchKey}
          onChange={handleSearchChange}
        />
        <TextField
          margin="dense"
          label="From"
          variant="outlined"
          type="date"
          name="searchDateFrom"
          disabled={loading}
          value={filters.searchDateFrom}
          onChange={handleSearchChange}
        />
        <TextField
          margin="dense"
          label="To"
          variant="outlined"
          type="date"
          name="searchDateTo"
          disabled={loading}
          value={filters.searchDateTo}
          onChange={handleSearchChange}
        />
      </div>
      <div className={classes.content}>
        {stores.pageData.length > 0 && (
          <Grid item lg={12} md={12} xs={12}>
            <Typography variant="h3" align="right">
              {stores.aggregate.inflow > 0 && (
                <>
                  Inflow:
                  <Chip
                    label={toCurrency(stores.aggregate.inflow)}
                    color="default"
                  />
                </>
              )}
              {stores.aggregate.outflow > 0 && (
                <>
                  Outflow:
                  <Chip
                    label={toCurrency(stores.aggregate.outflow)}
                    color="secondary"
                  />
                </>
              )}
              {stores.aggregate.diff > 0 && (
                <>
                  Aggregate:
                  <Chip
                    label={toCurrency(stores.aggregate.diff)}
                    color={stores.aggregate.diff > 0 ? "primary" : "default"}
                  />
                </>
              )}
            </Typography>
            <Typography color="textSecondary" variant="body2" align="left">
              {stores.totalCount} Records found. Page {filters.pageNumber} of{" "}
              {stores.numPages}
            </Typography>
          </Grid>
        )}
        <CustomisedTable
          data={stores.pageData}
          dataCount={stores.numPages}
          page={filters.pageNumber}
          columns={transactionColumns(onTransactionClick)}
          loading={loading}
          size="small"
          rowsPerPage={filters.pageCount}
          pageOptions={[5, 10, 25, 50, 100]}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
    </div>
  );
};
