import React, { useState } from 'react';
import Files from 'react-files';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@material-ui/core';
import { toDate, toStoreModel } from 'utils/helpers';
import { CustomisedTable } from 'components/common';
import { makeStyles } from '@material-ui/styles';
import { migrationsStoreColums } from 'components/columns';
import { http } from 'utils/http';
import { toast } from 'react-toastify';

const storeTypes = ['store', 'online', 'debt'];
const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
		width: 'fit-content'
	},
	formControl: {
		marginTop: theme.spacing(2),
		minWidth: 120
	},
	formControlLabel: {
		marginTop: theme.spacing(1)
	}
}));
export const StoreImport = ({ open = false, setOpen, assets = [] }) => {
	const classes = useStyles();
	const [jsonFile, setJsonFile] = useState({});
	const [storeData, setStoreData] = useState([]);
	const [selectedType, setSelectedStore] = useState('store');
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(0);
	const [selectedStores, setSelectedStores] = useState([]);

	const fileReader = new FileReader();
	fileReader.onload = ({ target }) => {
		setStoreData(JSON.parse(target.result));
	};

	const handleSelectOne = (event, id) => {
		const selectedIndex = selectedStores.indexOf(id);
		let newSelectedStores = [];

		if (selectedIndex === -1) {
			newSelectedStores = newSelectedStores.concat(selectedStores, id);
		} else if (selectedIndex === 0) {
			newSelectedStores = newSelectedStores.concat(selectedStores.slice(1));
		} else if (selectedIndex === selectedStores.length - 1) {
			newSelectedStores = newSelectedStores.concat(selectedStores.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelectedStores = newSelectedStores.concat(
				selectedStores.slice(0, selectedIndex),
				selectedStores.slice(selectedIndex + 1)
			);
		}

		setSelectedStores(newSelectedStores);
	};
	const save = () => {
		const migrationUrl = '/stores/migration';
		const stores = toStoreModel(selectedStores, storeData);
		http
			.post(migrationUrl, { stores })
			.then(({ data }) => {
				toast(data.message);
				setSelectedStores([]);
			})
			.catch((error) => {
				console.log('Error', error.message);
			});
	};
	const onSetAsset = (storeId, assetId) => {
		const storeCopy = [...storeData];
		let storeObj = storeCopy.findIndex((obj) => obj.id === storeId);
		storeCopy[storeObj].propertyId = assetId;
		setStoreData(storeCopy);
		handleSelectOne(null, storeId);
	};
	return (
		<Dialog
			onClose={setOpen}
			aria-labelledby='store-import'
			open={open}
			fullWidth
			maxWidth='md'
		>
			<DialogTitle id='store-import' onClose={setOpen}>
				Import store from a JSON file
			</DialogTitle>
			<DialogContent dividers>
				{jsonFile.name ? (
					<Typography variant='h6' align='center'>
						{`File info:: Name: ${jsonFile.name}, size: ${
							jsonFile.sizeReadable
						}, Modified: ${toDate(jsonFile.lastModified)}`}
					</Typography>
				) : null}
				<FormControl className={classes.formControl}>
					<InputLabel htmlFor='store-type'>Store type</InputLabel>
					<Select
						autoFocus
						value={selectedType}
						onChange={({ target }) => setSelectedStore(target.value)}
						inputProps={{
							name: 'store-type',
							id: 'store-type'
						}}
					>
						<MenuItem value=''>----</MenuItem>
						{storeTypes.map((type, typeIdx) => (
							<MenuItem value={type} key={typeIdx}>
								{type.toUpperCase()}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Files
					className='files-dropzone'
					accepts={['.json']}
					maxFiles={1}
					maxFileSize={10000000}
					minFileSize={0}
					clickable
					onChange={(file) => {
						setJsonFile(file[0]);
						fileReader.readAsText(file[0]);
					}}
				>
					Drop files here or click to upload
				</Files>
				<CustomisedTable
					data={storeData.slice(
						page * rowsPerPage,
						page * rowsPerPage + rowsPerPage
					)}
					dataCount={Math.ceil(storeData.length / rowsPerPage)}
					columns={migrationsStoreColums(assets, onSetAsset)[selectedType]}
					handlePageChange={({ selected }) => setPage(selected)}
					handleRowsPerPageChange={({ target }) => setRowsPerPage(target.value)}
					page={page}
					rowsPerPage={rowsPerPage}
					withSelect
					selectedData={selectedStores}
					size='small'
					handleSelectOne={handleSelectOne}
				/>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={() => save()} color='primary'>
					Save changes
				</Button>
			</DialogActions>
		</Dialog>
	);
};
