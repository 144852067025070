import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Details as DetailsIcon } from "@material-ui/icons";
import { toDate } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 100,
    margin: "0 auto",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "100%",
  },
  statsItem: {
    display: "flex",
    alignItems: "center",
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}));

export const ProductCard = ({ className, product, setProduct, ...rest }) => {
  const classes = useStyles();
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.imageContainer}>
          <img
            alt={product.name.charAt(0)}
            className={classes.image}
            src={product.coverImage}
          />
        </div>
        <Typography align="center" gutterBottom variant="h4">
          {product.name}
        </Typography>
        <Typography align="center" gutterBottom variant="h4">
          Rwf <strong>{product.price}</strong>
        </Typography>
        <Typography align="center" variant="body1">
          {product.description}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container justifyContent="space-between">
          <Grid className={classes.statsItem} item>
            <AccessTimeIcon className={classes.statsIcon} />
            <Typography display="inline" variant="body2">
              Registered {toDate(product.createdAt)}
            </Typography>
          </Grid>
          <Grid className={classes.statsItem} item onClick={setProduct}>
            <DetailsIcon className={classes.statsIcon} />
            <Typography display="inline" variant="body2">
              {product.detailsCounts} details
            </Typography>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
