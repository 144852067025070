import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  Container,
  CssBaseline,
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  useScrollTrigger,
  Zoom,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowUp as KeyboardArrowUpIcon } from "@material-ui/icons";
import { Header } from "../HomePage/Header";
import { Footer } from "../HomePage/Footer";
import {
  salaryInitialState,
  toFloatValues,
  salaryState,
} from "./constantUtils";
import { useMutation } from "@apollo/client";
import { SALARY_MUTATION } from "graphql/mutations";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}
export const SalaryCalculator = (props) => {
  const [values, setValues] = useState(salaryInitialState);
  const [salary, setSalary] = useState(salaryState);
  const [calculateSalary, { data, loading }] = useMutation(SALARY_MUTATION);

  useEffect(() => {
    if (data && data.calculateSalary) {
      setSalary({ id: 2, ...data.calculateSalary.salary });
    }
  }, [data]);

  const onHandleChange = ({ target: { name, value } }) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  const onSend = () => {
    const data = toFloatValues(values);
    calculateSalary({ variables: data });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <Typography variant="h2">D2D Store</Typography>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Container>
        <Header title="D2D store - Salary calculator" />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={false}
                  fullWidth
                  label="Gross salary"
                  placeholder="Enter the gross salary"
                  variant="outlined"
                  name="grossSalary"
                  type="number"
                  disabled={Boolean(values.netSalary)}
                  value={values.grossSalary}
                  onChange={onHandleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  error={false}
                  fullWidth
                  label="Net salary"
                  placeholder="Enter the net salary"
                  variant="outlined"
                  name="netSalary"
                  type="number"
                  disabled={Boolean(values.grossSalary)}
                  value={values.netSalary}
                  onChange={onHandleChange}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={2} lg={2}>
                <TextField
                  error={false}
                  fullWidth
                  label="Amount"
                  placeholder="Amount"
                  variant="outlined"
                  name="amount"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  error={false}
                  fullWidth
                  label="Percentage"
                  placeholder="Percentage"
                  variant="outlined"
                  name="percentage"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="select-field">Pct Field</InputLabel>
                  <Select
                    labelId="select-field"
                    value={values.recordType}
                    name="recordType"
                  >
                    <MenuItem value="">---</MenuItem>
                    {selectFields.map(({ id, name }, choiceIdx) => (
                      <MenuItem value={id} key={choiceIdx}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>*/}
              <Grid item md={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={() => onSend()}
                >
                  Calculate
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <div style={{ height: 400, width: "100%" }}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Pension</TableCell>
                      <TableCell align="right">Maternity</TableCell>
                      <TableCell align="right">Tax</TableCell>
                      <TableCell align="right">Net pay</TableCell>
                      <TableCell align="right">Gross salary</TableCell>
                      <TableCell align="right">Net salary</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[salary].map((row, rowId) => (
                      <TableRow key={rowId}>
                        <TableCell align="right">{row.pension}</TableCell>
                        <TableCell align="right">{row.maternity}</TableCell>
                        <TableCell align="right">{row.tax}</TableCell>
                        <TableCell align="right">{row.netPay}</TableCell>
                        <TableCell align="right">{row.grossSalary}</TableCell>
                        <TableCell align="right">{row.netSalary}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
        <Footer
          title="AaJA"
          description="Save as much money as possible. For sure, you gonna need it tomorrow!"
        />
      </Container>
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
};
