import { gql } from '@apollo/client';

export const ADD_PROPERTY_MUTATION = gql`
  mutation AddPropertyMutation(
    $price: Float!
    $name: String!
    $isActive: Boolean!
    $coverImage: String!
    $description: String!
  ) {
    createProperty(
      name: $name
      description: $description
      price: $price
      coverImage: $coverImage
      isActive: $isActive
    ) {
      message
    }
  }
`;
export const ADD_PROP_DETAIL_MUTATION = gql`
  mutation AddPropDetailMutation(
    $amount: Float!
    $title: String!
    $isInflow: Boolean!
    $propertyId: Int!
  ) {
    addPropDetail(
      title: $title
      amount: $amount
      isInflow: $isInflow
      propertyId: $propertyId
    ) {
      message
      newDetail {
        id
        title
        type
        amount
      }
    }
  }
`;
