import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "graphiql/graphiql.min.css";
import "react-datepicker/dist/react-datepicker.css";
import Routes from "./Routes";
import { ApolloProvider } from "@apollo/client";
import { client } from "graphql/client";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "providers/auth";
import { ErrorBoundary } from "components/ErrorBoundary";

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <ErrorBoundary location={null}>
        <ApolloProvider client={client}>
          <AuthProvider>
            <Router>
              <Routes />
            </Router>
          </AuthProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
