import React from "react";
import clsx from "clsx";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import { tableStyles } from "assets/tableStyles";
import { Loading } from "components/Loading";
import { NoDisplayData } from "components/NoDisplayData";
import { Paginate } from "./Paginate";

export const CustomisedTable = ({
  className,
  data = [],
  dataCount = 0,
  page = 1,
  columns = [],
  selectedData = [],
  rowsPerPage = 10,
  pageOptions = [5, 10, 20],
  withSelect = false,
  loading = false,
  withPagination = true,
  handleSelectOne,
  handlePageChange,
  handleSelectAll,
  handleRowsPerPageChange,
  size = "medium",
  ...rest
}) => {
  const classes = tableStyles();
  const renderCell = (item, column) => {
    if (column.content) return column.content(item);
    return _.get(item, column.path);
  };
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.tableContent}>
        {loading ? (
          <Loading />
        ) : data.length ? (
          <Table size={size}>
            <TableHead>
              <TableRow>
                {withSelect && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedData.length === data.length}
                      color="primary"
                      indeterminate={
                        selectedData.length > 0 &&
                        selectedData.length < data.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                )}
                {columns.map(({ label }, columnIdx) => (
                  <TableCell key={columnIdx}>{label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, itemIdx) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={itemIdx}
                  selected={selectedData.indexOf(item.id) !== -1}
                >
                  {withSelect && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedData.indexOf(item.id) !== -1}
                        color="primary"
                        onChange={(event) => handleSelectOne(event, item.id)}
                        value="true"
                      />
                    </TableCell>
                  )}
                  {columns.map((cellColumn, cellColumnIdx) => (
                    <TableCell key={cellColumnIdx}>
                      {renderCell(item, cellColumn)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <NoDisplayData />
        )}
      </CardContent>
      {!loading && data.length !== 0 && withPagination && (
        <CardActions className={classes.actions}>
          {/* <TablePagination
            component='div'
            count={dataCount}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={pageOptions}
          /> */}
          <Paginate
            pageCount={dataCount}
            pageRangeDisplayed={3}
            marginPagesDisplayed={3}
            onPageChange={handlePageChange}
          />
        </CardActions>
      )}
    </Card>
  );
};
