import { gql } from "@apollo/client";

export const SALARY_MUTATION = gql`
  mutation SalaryMutation($netSalary: Float!, $grossSalary: Float!) {
    calculateSalary(netSalary: $netSalary, grossSalary: $grossSalary) {
      message
      salary {
        pension
        maternity
        tax
        grossSalary
        netSalary
        netPay
      }
    }
  }
`;
