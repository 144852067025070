import React, {
  createContext,
  useEffect,
  useContext,
  useState,
  useCallback,
} from "react";
import { GET_AUTH_USER_INFO, WELCOME_QUERY } from "graphql/queries/user";
import { AUTH_TOKEN } from "utils/constants";
import { client } from "graphql/client";

const initialState = {
  isAuthenticated: false,
  user: {},
  authenticating: true,
  households: [],
};
const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(initialState);

  const handleChangeAuth = (data = null) => {
    let newAuth = { ...initialState, authenticating: false };
    if (data) {
      newAuth = {
        ...newAuth,
        isAuthenticated: true,
        user: data.me,
        households: data.myHouseholdMemberships,
      };
    }
    setAuth(newAuth);
  };

  const handleFetchUserInfo = useCallback(async () => {
    try {
      const token = localStorage[AUTH_TOKEN];
      let query = token ? GET_AUTH_USER_INFO : WELCOME_QUERY;
      if (token) {
        const { data } = await client.query({ query });
        if (data.me) {
          handleChangeAuth(data);
        } else {
          handleChangeAuth();
          console.log("=====>NoLg");
        }
      } else {
        handleChangeAuth();
        console.log("=====>NoT");
      }
    } catch (error) {
      handleChangeAuth();
      console.log("=====>Err");
    }
  }, []);
  useEffect(() => {
    handleFetchUserInfo();
  }, [handleFetchUserInfo]);

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        setAuthUser: handleChangeAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
