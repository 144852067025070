import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import { CustomisedTable } from "components/common";
import { prodDetailColumns } from "components/columns";
import { prodDetailInitials } from "utils/initialStates";
import { useMutation } from "@apollo/client";
import { ADD_PROP_DETAIL_MUTATION } from "graphql/mutations";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  statsItem: {
    display: "flex",
    alignItems: "center",
  },
}));

export const Viewpropertydetail = ({ open, setOpen, product = {} }) => {
  const classes = useStyles();
  const [prodDetail, setProdDetail] = useState(prodDetailInitials);
  const [theProdDetails, setTheProdDetails] = useState([]);
  const [addPropDetail, { data, loading }] = useMutation(
    ADD_PROP_DETAIL_MUTATION,
    { errorPolicy: "ignore" }
  );
  const onHandleChange = ({ target: { name, checked, value } }) => {
    const inputValue = name === "isInflow" ? checked : value;
    setProdDetail({ ...prodDetail, [name]: inputValue });
  };
  useEffect(() => {
    if (data && data.addPropDetail) {
      setProdDetail(prodDetailInitials);
      setTheProdDetails([-1, data.addPropDetail.newDetail, ...theProdDetails]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    if (product.stores) {
      setTheProdDetails(product.stores);
    }
  }, [product.stores]);
  const onSave = () => {
    prodDetail.amount = Number(prodDetail.amount);
    prodDetail.propertyId = Number(product.id);
    addPropDetail({ variables: prodDetail });
  };
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={setOpen}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="add-dialog">{`Product: ${product.name}`}</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="space-between">
          <Grid className={classes.statsItem} item>
            <Typography display="inline" variant="h5">
              IN: {product.detailsIns || "Not yet"}
            </Typography>
          </Grid>
          <Grid className={classes.statsItem} item>
            <Typography display="inline" variant="h5">
              OUT: {product.detailsOuts || "Still good"}
            </Typography>
          </Grid>
          <Grid className={classes.statsItem} item>
            <Typography display="inline" variant="h5">
              PRICE: {product.price}
            </Typography>
          </Grid>
        </Grid>
        <DialogContentText>Property details below</DialogContentText>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12}>
            <TextField
              autoFocus
              margin="dense"
              variant="outlined"
              label="Title"
              fullWidth
              required
              name="title"
              value={prodDetail.title}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="dense"
              variant="outlined"
              label="Amount"
              fullWidth
              required
              type="number"
              name="amount"
              value={prodDetail.amount}
              onChange={onHandleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  name="isInflow"
                  checked={prodDetail.isInflow}
                  onChange={onHandleChange}
                />
              }
              label="Is income?"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Button variant="outlined" color="primary" onClick={() => onSave()}>
              {loading ? "Saving,..." : "Save"}
            </Button>
          </Grid>
        </Grid>
        <CustomisedTable
          columns={prodDetailColumns}
          data={theProdDetails}
          withPagination={false}
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={setOpen} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
