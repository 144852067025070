import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { API_URL } from "utils/constants";
import { authLink, errorLink } from "./utils";

const httpLink = createHttpLink({
  uri: ({ operationName }) => `${API_URL}?${operationName}`,
});

export const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  connectToDevTools: process.env.NODE_ENV === "development",
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-first",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "ignore",
    },
    mutate: {
      errorPolicy: "ignore",
    },
  },
});
