import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import Widget from 'fusioncharts/fusioncharts.widgets';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

const FusionChart = ({ type = 'column2d', chartOptions = {} }) => {
  const ChartType = type === 'column2d' ? Column2D : Widget;
  const chartConfigs = {
    ...chartOptions,
    type: type,
    width: '100%',
    height: '100%',
    dataFormat: 'json',
  };

  ReactFC.fcRoot(FusionCharts, ChartType, FusionTheme);
  return <ReactFC {...chartConfigs} />;
};

export default FusionChart;
