import { gql } from "@apollo/client";

export const GET_PROPERTIES_QUERY = gql`
  query Properties(
    $search: String!
    $pageCount: Int!
    $pageNumber: Int!
    $isActive: String!
  ) {
    properties(
      search: $search
      pageCount: $pageCount
      pageNumber: $pageNumber
      isActive: $isActive
    ) {
      pageData {
        id
        name
        price
        description
        coverImage
        createdAt
        detailsCounts
        detailsIns
        detailsOuts
        stores {
          id
          description
          isInflow
          amount
        }
      }
      numPages
      totalCount
    }
    propertiesDetail {
      count
      totalAmount
    }
  }
`;
