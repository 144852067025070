import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
} from "@material-ui/core";
import { useAuth } from "providers/auth";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD_MUTATION } from "graphql/mutations";
import { toast } from "react-toastify";

const useStyles = makeStyles(() => ({
  root: {},
  text: {
    marginTop: "1rem",
  },
}));

const initialState = {
  email: "",
  oldPassword: "",
  newPassword: "",
  confirm: "",
};
export const ResetPassword = ({
  className,
  userInfo,
  title = "Change password",
  ...rest
}) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [resetPassword, { data, loading }] = useMutation(
    RESET_PASSWORD_MUTATION,
    {
      errorPolicy: "ignore",
    }
  );

  const [values, setValues] = useState(initialState);

  useEffect(() => {
    setValues((prev) => ({ ...prev, email: userInfo?.email || user.email }));
  }, [user, userInfo]);
  useEffect(() => {
    if (data?.resetPassword) {
      toast("Password updated");
      localStorage.clear();
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 3000);
    }
  }, [data]);
  const handleChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };
  const handleSave = () => {
    const { newPassword, confirm, ...rest } = values;
    if (!newPassword || newPassword !== confirm) {
      toast("Password dont match!!");
      return;
    }
    resetPassword({ variables: { newPassword, ...rest } });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader subheader={title} title="Password" />
      <Divider />
      <CardContent>
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={values.email}
          disabled
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Old assword"
          name="oldPassword"
          onChange={handleChange}
          type="password"
          className={classes.text}
          value={values.oldPassword}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="New Password"
          name="newPassword"
          onChange={handleChange}
          type="password"
          className={classes.text}
          value={values.newPassword}
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Confirm password"
          name="confirm"
          onChange={handleChange}
          className={classes.text}
          type="password"
          value={values.confirm}
          variant="outlined"
        />
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleSave()}
          disabled={loading}
        >
          {loading ? "Changing" : "Change password"}
        </Button>
      </CardActions>
    </Card>
  );
};

ResetPassword.propTypes = {
  className: PropTypes.string,
};
