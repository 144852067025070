import React from "react";

import { MinimalTopbar } from "../components/common";
import { useLayoutStyles } from "./styles";

export const Minimal = (props) => {
  const { children } = props;

  const classes = useLayoutStyles();

  return (
    <div className={classes.root}>
      <MinimalTopbar />
      <main className={classes.content}>{children}</main>
    </div>
  );
};
