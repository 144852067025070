import React, { useState, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useQuery } from "@apollo/client";
import { GET_STORES_QUERY } from "graphql/queries";
import { Loading } from "components/Loading";
import { NoDisplayData } from "components/NoDisplayData";
import { CreateOrUpdateRecord } from "components/Models";
import { Link } from "react-router-dom";
import { initialStoreFilters } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

export const TransactionsDash = ({ className, ...rest }) => {
  const classes = useStyles();
  const [stores, setStores] = useState([]);
  const [openAddRecord, setOpenAddRecord] = useState(false);

  const { loading, data, refetch } = useQuery(GET_STORES_QUERY, {
    variables: { ...initialStoreFilters, pageCount: 5 },
  });
  useEffect(() => {
    if (data?.stores) {
      setStores(data.stores.pageData);
    }
  }, [data]);
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CreateOrUpdateRecord
        open={openAddRecord}
        setOpen={() => setOpenAddRecord(false)}
        refetch={refetch}
      />
      <CardHeader
        action={
          <Button
            color="primary"
            size="small"
            variant="outlined"
            onClick={() => setOpenAddRecord(true)}
          >
            New entry
          </Button>
        }
        title="Latest stores"
      />
      <Divider />
      <CardContent className={classes.content}>
        {loading ? (
          <Loading />
        ) : stores.length ? (
          <PerfectScrollbar>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Created at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stores.map((store, idx) => (
                  <TableRow hover key={idx}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{store.amount}</TableCell>
                    <TableCell>{store.description}</TableCell>
                    <TableCell>
                      {moment(store.actionDate).format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        ) : (
          <NoDisplayData />
        )}
      </CardContent>
      {stores.length > 4 && (
        <>
          <Divider />
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              size="small"
              variant="text"
              component={Link}
              to="/transactions"
            >
              View all <ArrowRightIcon />
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};
