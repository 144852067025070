import { Menu, MenuItem } from "@material-ui/core";

export const D2DMenu = ({
  options = [],
  selectedOption = null,
  menuAnchorEl = null,
  handleClose,
  onItemClick,
}) => {
  return (
    <Menu
      id="select-transaction"
      anchorEl={menuAnchorEl}
      keepMounted
      open={Boolean(menuAnchorEl)}
      onClose={handleClose}
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          selected={option.value === selectedOption}
          onClick={() => onItemClick(option)}
        >
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );
};
