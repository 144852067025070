import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Button,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	IconButton,
	Typography
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { toDate } from 'utils/helpers';
import { NoDisplayData, Loading } from 'components';
import { Link } from 'react-router-dom';
import { useGetProducts } from 'helpers';

const useStyles = makeStyles(() => ({
	root: {
		height: '100%'
	},
	content: {
		padding: 0
	},
	image: {
		height: 48,
		width: 48
	},
	actions: {
		justifyContent: 'flex-end'
	}
}));

export const AssetsDashboard = ({ className, ...rest }) => {
	const classes = useStyles();
	const [products, isLoading] = useGetProducts({
		pageCount: 5,
		pageNumber: 1,
		isActive: 'yes'
	});

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardHeader title='My assets' subtitle={`${products.length} in total`} />
			<Divider />
			<CardContent className={classes.content}>
				{isLoading ? (
					<Loading />
				) : products.properties.pageData.length ? (
					<List>
						{products.properties.pageData.map((product, i) => (
							<ListItem divider={i < products.length - 1} key={product.id}>
								<ListItemAvatar>
									<Typography variant='h1'>{product.name.charAt(0)}</Typography>
									{/* <img
                  alt='Product'
                  className={classes.image}
                  src={product.imageUrl}
                /> */}
								</ListItemAvatar>
								<ListItemText
									primary={product.name}
									secondary={`Created ${toDate(product.createdAt)}`}
								/>
								<IconButton edge='end' size='small'>
									<MoreVertIcon />
								</IconButton>
							</ListItem>
						))}
					</List>
				) : (
					<NoDisplayData />
				)}
			</CardContent>
			{products.properties.pageData.length === 5 && (
				<>
					<Divider />
					<CardActions className={classes.actions}>
						<Button
							component={Link}
							color='primary'
							size='small'
							variant='text'
							to='/products'
						>
							View all <ArrowRightIcon />
						</Button>
					</CardActions>
				</>
			)}
		</Card>
	);
};
