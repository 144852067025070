import { Button } from "@material-ui/core";
import { toGerund } from "utils/helpers";

export const JSButton = ({ title = "Save", isUpdate, loading, ...props }) => {
  return (
    <Button {...props} disabled={loading}>
      {loading ? toGerund(title) : title}
    </Button>
  );
};
