import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CREATE_BUDGET } from "graphql/mutations/budgets";
import { JSButton } from "components/common/JButton";
import { toDateF } from "utils/helpers";
import { useAuth } from "providers/auth";

const initialState = {
  name: "",
  description: "",
  status: "draft",
  startDate: null,
  endDate: null,
};

const statuses = ["draft", "pending", "approved"];
const initialDates = [null, null];
export const CreateOrUpdateBudget = ({ open, onClose, budget, refetch }) => {
  const { user } = useAuth();
  const [values, setValues] = useState(initialState);
  const [dateRange, setDateRange] = useState(initialDates);
  const [saveBudget, { loading: saving, data, reset }] =
    useMutation(CREATE_BUDGET);

  useEffect(() => {
    if (data?.createBudget) {
      onClose();
      refetch();
      setValues(initialState);
      setDateRange(initialDates);
      setTimeout(() => {
        reset();
      }, 1000);
    }
  }, [data, onClose, refetch, reset]);

  const handleChange = ({ target: { name, value } }) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  const [startDate, endDate] = dateRange;
  const handleSave = () => {
    saveBudget({
      variables: {
        ...values,
        startDate: toDateF(startDate),
        endDate: toDateF(endDate),
        householdId: user.householdSet?.[0]?.id,
      },
    });
  };
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="add-dialog">Create a new budget</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill in the required fields
        </DialogContentText>
        <Grid container spacing={1}>
          <Grid item md={8} xs={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              name="name"
              label="Budget title"
              value={values.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormControl variant="outlined" size="small" fullWidth>
              <Select
                labelId="status"
                value={values.status}
                name="status"
                onChange={handleChange}
              >
                <MenuItem value="">---</MenuItem>
                {statuses.map((status, choiceIdx) => (
                  <MenuItem value={status} key={status}>
                    {status.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              name="description"
              label="Description"
              value={values.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="budget-dates">Budget duration</InputLabel>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              inline
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <JSButton loading={saving} onClick={handleSave} />
      </DialogActions>
    </Dialog>
  );
};
