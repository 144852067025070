import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@material-ui/core";
import { BusinessCenter, Money } from "@material-ui/icons";
// import MoneyIcon from '@material-ui/icons/Money';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
}));

export const TotalTransations = ({ className, storeCount = 0, ...rest }) => {
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              TOTAL TRANSACTIONS
            </Typography>
            <Typography variant="h3">{storeCount}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <Money className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          <BusinessCenter className={classes.differenceIcon} />
          <Typography className={classes.differenceValue} variant="body2">
            Click to
          </Typography>
          <Typography className={classes.caption} variant="caption">
            View details
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};
