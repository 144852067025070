import { gql } from "@apollo/client";

export const DASHBOARD_INFO_QUERY = gql`
  query {
    totalInflow
    totalOutflow
    storeCount
    storeAggregate {
      inflow
      outflow
      percent
    }
    propertiesDetail {
      count
      totalAmount
    }
  }
`;
export const GET_STORES_QUERY = gql`
  query Stores(
    $searchKey: String!
    $searchDateFrom: String!
    $searchDateTo: String!
    $pageCount: Int!
    $pageNumber: Int!
  ) {
    stores(
      searchKey: $searchKey
      searchDateFrom: $searchDateFrom
      searchDateTo: $searchDateTo
      pageCount: $pageCount
      pageNumber: $pageNumber
    ) {
      pageData {
        id
        amount
        description
        property {
          id
          name
        }
        budgetItem {
          id
          name
        }
        isInflow
        actionDate
        recordType
      }
      numPages
      totalCount
      aggregate {
        inflow
        outflow
        diff
      }
    }
  }
`;
export const GET_MONTLY_STORES = gql`
  query MontlyStore($time: String!, $isInflow: Boolean!) {
    monthlyStore(isInflow: $isInflow, time: $time) {
      label
      value
    }
  }
`;
export const GET_STORE_RATIO = gql`
  query {
    storeRatio {
      inflow
      outflow
      percent
    }
  }
`;
export const GET_RECURRING_STORES = gql`
  query RecurringStore($searchKey: String) {
    recurringStores(searchKey: $searchKey) {
      pageData {
        id
        name
      }
    }
  }
`;
