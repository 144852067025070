import { gql } from "@apollo/client";

export const GET_BUDGETS_QUERY = gql`
  query Budgets(
    $searchKey: String!
    $searchStartDate: String!
    $searchEndDate: String!
    $searchMember: String!
  ) {
    budgets(
      searchKey: $searchKey
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      searchMember: $searchMember
    ) {
      pageData {
        id
        name
        description
        status
      }
    }
  }
`;

export const GET_BUDGET_QUERY = gql`
  query CurrentBudget($budgetId: String!) {
    currentBudget(budgetId: $budgetId) {
      name
      startDate
      endDate
      amount
      amountSpent
      budgetItems {
        id
        name
        amount
        amountSpent
      }
    }
  }
`;
