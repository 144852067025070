import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Toolbar, Button, IconButton, Typography } from "@material-ui/core";
import { LockOpen, Search as SearchIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useAuth } from "providers/auth";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
}));

export const Header = ({ title }) => {
  const classes = useStyles();
  const { user, isAuthenticated } = useAuth();
  return (
    <Toolbar className={classes.toolbar}>
      <Typography
        component="h2"
        variant="h5"
        color="inherit"
        align="center"
        noWrap
        className={classes.toolbarTitle}
      >
        {title}
      </Typography>
      <IconButton>
        <SearchIcon />
      </IconButton>
      <Button
        variant="outlined"
        size="small"
        to={isAuthenticated ? "/dashboard" : "/sign-in"}
        component={Link}
      >
        <i className={classes.icon}>
          {isAuthenticated ? <LockOpen /> : <LockOpen />}
        </i>
        {isAuthenticated ? user?.firstName : "Log in"}
      </Button>
    </Toolbar>
  );
};
