import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import { TableToolbar, Loading, NoDisplayData } from "components";
import { ProductCard } from "./ProductCard";
import { useMutation } from "@apollo/client";
import { AddProperty } from "components/Models";
import { productInitials } from "utils/initialStates";
import { ADD_PROPERTY_MUTATION } from "graphql/mutations";
import { Viewpropertydetail } from "components/Models/ViewPropertyDetail";
import { useGetProducts } from "helpers";
import { Paginate } from "components/common";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

export const ProductList = () => {
  const classes = useStyles();
  const [rowsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [openAddProperty, setOpenAddProperty] = useState(false);
  const [openViewProperty, setOpenViewProperty] = useState(false);
  const [productValues, setProductValues] = useState(productInitials);
  const [currentProduct, setCurrentProduct] = useState({});
  const [createProperty, { loading: mutLoading, data: mutData }] = useMutation(
    ADD_PROPERTY_MUTATION,
    { errorPolicy: "ignore" }
  );

  const [products, isLoading] = useGetProducts({
    search,
    pageCount: rowsPerPage,
    pageNumber: page,
    isActive: "all",
  });

  useEffect(() => {
    if (mutData && mutData.createProperty) {
      setProductValues(productInitials);
      setOpenAddProperty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutData]);
  const onHandleChange = ({ target: { name, value, checked } }) => {
    const inputValue = name === "isActive" ? checked : value;
    setProductValues({ ...productValues, [name]: inputValue });
  };
  const onSaveProduct = () => {
    productValues.price = parseFloat(productValues.price) || 0;

    createProperty({ variables: productValues });
  };
  const viewProduct = (product) => {
    setCurrentProduct(product);
    setOpenViewProperty(true);
  };
  const { properties, propertiesDetail } = products;
  const detailsTotalCount = propertiesDetail?.count;
  return (
    <div className={classes.root}>
      <TableToolbar
        btnLabel="Add asset"
        searchHolder="Search an asset"
        onChange={({ target }) => setSearch(target.value)}
        onBtnClick={() => setOpenAddProperty(true)}
      />
      <AddProperty
        open={openAddProperty}
        setOpen={() => setOpenAddProperty(false)}
        values={productValues}
        onHandleChange={onHandleChange}
        loading={mutLoading}
        onSave={onSaveProduct}
      />
      <Viewpropertydetail
        open={openViewProperty}
        setOpen={() => setOpenViewProperty(false)}
        product={currentProduct}
      />
      <div className={classes.content}>
        {detailsTotalCount > 0 && (
          <Typography color="textSecondary" gutterBottom variant="body2">
            {detailsTotalCount} Records found. Page {page} of{" "}
            {properties.numPages}
          </Typography>
        )}
        <Grid container spacing={3}>
          {isLoading ? (
            <Loading />
          ) : detailsTotalCount ? (
            <>
              <Grid item lg={12} md={12} xs={12}>
                <Typography gutterBottom variant="h3" align="right">
                  {`Total assets: ${detailsTotalCount}, value: RwF ${propertiesDetail?.totalAmount}`}
                </Typography>
              </Grid>
              {properties.pageData.map((product, productIdx) => (
                <Grid item key={productIdx} lg={4} md={6} xs={12}>
                  <ProductCard
                    product={product}
                    setProduct={() => viewProduct(product)}
                  />
                </Grid>
              ))}
            </>
          ) : (
            <NoDisplayData />
          )}
        </Grid>
      </div>
      {properties.pageData.length > 0 && (
        <div className={classes.pagination}>
          <div className={classes.actions}>
            <Paginate
              pageCount={properties.numPages}
              pageRangeDisplayed={3}
              marginPagesDisplayed={3}
              onPageChange={({ selected }) => setPage(selected + 1)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
