export const monthlyChartOptions = (data = []) => {
  return {
    dataSource: {
      chart: {
        caption: "Current year montly expenses tranctions",
        subCaption: "In K = One Thousands RwF",
        xAxisName: "Date in Month, Year",
        yAxisName: "Amount of expenses (K)",
      },
      data,
    },
  };
};
export const storeGaugeOptions = (percent = 0) => {
  return {
    dataSource: {
      chart: {
        caption: "Expenditure ratio",
        subcaption: "Income over expenses",
        plotToolText: "Current ratio: $value",
        theme: "fusion",
        chartBottomMargin: "50",
        showValue: "1",
      },
      colorRange: {
        color: [
          {
            minValue: "0",
            maxValue: "30",
            code: "#6baa01",
          },
          {
            minValue: "30",
            maxValue: "70",
            code: "#f8bd19",
          },
          {
            minValue: "70",
            maxValue: "100",
            code: "#e44a00",
          },
        ],
      },
      dials: {
        dial: [
          {
            value: percent,
          },
        ],
      },
      trendPoints: {
        point: [
          {
            startValue: "70",
            color: "#0075c2",
            dashed: "1",
          },
          {
            startValue: "90",
            endValue: "100",
            color: "#ff0000",
            radius: "185",
            innerRadius: "80",
          },
        ],
      },
      annotations: {
        origw: "450",
        origh: "300",
        autoscale: "1",
        showBelow: "0",
      },
    },
  };
};

export const errorMessages = {
  decodeToken: "Error decoding signature",
  noPermission: "You do not have permission to perform this action",
};
export const AUTH_TOKEN = "auth-token";
export const REST_TOKEN = "auth-token-r";
export const storeChoices = [
  { id: "use", name: "Store" },
  { id: "debt", name: "Debt" },
  { id: "online", name: "Online" },
];
export const API_URL = `${process.env.REACT_APP_API_URL}/api/d2dstore/`;
export const defaultQuery = `
# Type queries here.
# with completion, validation, and hovers
# Here's an example query to get you started:

query {
  me {
    email
    userName
  }
}
`;
