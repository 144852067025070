import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Typography } from "@material-ui/core";
import { useAuth } from "providers/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

export const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src="/images/user_profile.png"
        to="/settings"
      />
      <Typography className={classes.name} variant="h4">
        {user.firstName || user.userName}
      </Typography>
      <Typography variant="body2">Aja D2D Store</Typography>
      {/* <Typography variant="body2">Software Engineer</Typography> */}
    </div>
  );
};
