import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import { monthlyChartOptions } from "utils/constants";
import FusionChart from "components/common/FusionChart";
import { useQuery } from "@apollo/client";
import { GET_MONTLY_STORES } from "graphql/queries";
import { Loading } from "components";
import { D2DMenu } from "components/common";

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: "relative",
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const options = {
  type: [
    { label: "Expense", value: "x" },
    { label: "Income", value: "y" },
  ],
  time: [
    { label: "Last 2 years", value: "2_years" },
    { label: "Last a year", value: "1_year" },
    { label: "Last year", value: "last_year" },
    { label: "Current year", value: "current_year" },
    { label: "Last month", value: "last_month" },
    { label: "Current month", value: "current_month" },
  ],
};
const initialOptionStates = {
  anchor: null,
  options: [],
};
const initialFilters = {
  type: options.type[0].value,
  time: options.time[0].value,
  selectedType: "type",
};
export const MonthlyStoresChart = ({ className, ...rest }) => {
  const classes = useStyles();
  const [menuSelector, setMenuSelector] = React.useState(initialOptionStates);
  const [graphFilters, setGraphFilters] = React.useState(initialFilters);
  const [monthlyStores, setMontlyStores] = useState([]);
  const { loading, data } = useQuery(GET_MONTLY_STORES, {
    variables: { time: graphFilters.time, isInflow: false },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (data && data.monthlyStore) {
      setMontlyStores(data.monthlyStore);
    }
  }, [data, graphFilters]);

  const handleOpen = (type) => (event) => {
    setMenuSelector({ anchor: event.currentTarget, options: options[type] });
    setGraphFilters((prev) => ({ ...prev, selectedType: type }));
  };
  const handleItemSelect = (selected) => {
    setGraphFilters((prev) => ({
      ...prev,
      [prev.selectedType]: selected.value,
    }));
    setMenuSelector(initialOptionStates);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <D2DMenu
        options={menuSelector.options}
        menuAnchorEl={menuSelector.anchor}
        handleClose={() => setMenuSelector(initialOptionStates)}
        onItemClick={handleItemSelect}
        selectedOption={graphFilters[graphFilters.selectedType]}
      />
      <CardHeader
        action={
          <>
            <Button size="small" variant="text" onClick={handleOpen("type")}>
              {options.type?.find((t) => t.value === graphFilters.type)?.label}
              <ArrowDropDownIcon />
            </Button>
            <Button
              size="small"
              variant="text"
              onClick={handleOpen("time")}
              disabled={loading}
            >
              {options.time?.find((t) => t.value === graphFilters.time)?.label}
              <ArrowDropDownIcon />
            </Button>
          </>
        }
        title="Montly store graphical representation"
      />
      <Divider />
      <CardContent>
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.chartContainer}>
            <FusionChart chartOptions={monthlyChartOptions(monthlyStores)} />
          </div>
        )}
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text">
          Overview <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};
