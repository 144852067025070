import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { DashboardCounts } from './counts';
import { MonthlyStoresChart, StoreGaugeView } from './graphs';
import { AssetsDashboard } from './AssetsDashboard';
import { TransactionsDash } from './TransactionsDash';
import { dashboardInitials } from 'utils/initialStates';
import { useQuery } from '@apollo/client';
import { DASHBOARD_INFO_QUERY } from 'graphql/queries/store';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export const Dashboard = () => {
  const classes = useStyles();
  const [dashboardInfo, setDashboardInfo] = useState(dashboardInitials);
  const { loading, data } = useQuery(DASHBOARD_INFO_QUERY);
  useEffect(() => {
    if (data) {
      setDashboardInfo(data);
    }
  }, [data]);
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <DashboardCounts dashboardData={dashboardInfo} loading={loading} />
        </Grid>
        <Grid item lg={8} md={8} xl={9} xs={12}>
          <MonthlyStoresChart />
        </Grid>
        <Grid item lg={4} md={4} xl={3} xs={12}>
          <StoreGaugeView store={dashboardInfo} loading={loading} />
        </Grid>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <AssetsDashboard />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <TransactionsDash />
        </Grid>
      </Grid>
    </div>
  );
};
