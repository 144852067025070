import {
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon, ViewArray, Check } from "@material-ui/icons";

export const budgetColumns = (onRecordClick) => [
  { path: "name", label: "Name" },
  { path: "description", label: "Description" },
  {
    content: (item) => (
      <Typography
        aria-label="Status"
        color={item.status === "APPROVED" ? "primary" : "default"}
      >
        {item.status}
      </Typography>
    ),
    label: "Status",
  },
  {
    content: (item) => (
      <ButtonGroup variant="outlined" size="small">
        <Tooltip title="View items">
          <IconButton
            aria-label="View items"
            color="secondary"
            onClick={() => onRecordClick(item, "view")}
          >
            <ViewArray />
          </IconButton>
        </Tooltip>
        {item.status === "DRAFT" ? (
          <>
            <Tooltip title="Add to list">
              <IconButton
                aria-label="Add to list"
                color="secondary"
                onClick={() => onRecordClick(item, "addItems")}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Approve">
              <IconButton
                aria-label="Approve"
                color="secondary"
                onClick={() => onRecordClick(item, "approve")}
              >
                <Check />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <IconButton aria-label="Approved" color="secondary" disabled>
            Approved
          </IconButton>
        )}
      </ButtonGroup>
    ),
    label: "Actions",
  },
];
