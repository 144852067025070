const { makeStyles } = require('@material-ui/styles');

export const tableStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  tableContent: {
    padding: 0,
  },
  inner: {},
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));
