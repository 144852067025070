import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import {
  Dashboard,
  People,
  ShoppingBasket,
  AccountBox,
  Settings,
  DataUsage,
  Money,
} from "@material-ui/icons";

import { Profile, SidebarNav } from ".";
import { useAuth } from "providers/auth";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

export const Sidebar = ({ open, variant, onClose, className, ...rest }) => {
  const classes = useStyles();
  const { user } = useAuth();

  const pages = [
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <Dashboard />,
    },
    {
      title: "Transactions",
      href: "/transactions",
      icon: <ShoppingBasket />,
    },
    {
      title: "Products",
      href: "/products",
      icon: <ShoppingBasket />,
    },
    {
      title: "Budgeting",
      href: "/budgets",
      icon: <Money />,
    },
    {
      title: "Account",
      href: "/account",
      icon: <AccountBox />,
    },
    {
      title: "Settings",
      href: "/settings",
      icon: <Settings />,
    },
    ...(user.isSuperuser
      ? [
          {
            title: "Users",
            href: "/users",
            icon: <People />,
          },
          {
            title: "Data migation",
            href: "/db-migration",
            icon: <DataUsage />,
          },
          {
            title: "API console",
            href: "/console",
            icon: <DataUsage />,
          },
        ]
      : []),
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};
