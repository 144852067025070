import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Badge, Hidden, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import { useMutation } from "@apollo/client";
import { LOGOUT_MUTATION } from "graphql/mutations";
import { AUTH_TOKEN, REST_TOKEN } from "utils/constants";
import { ReactComponent as AppLogo } from "components/icons/Logo.svg";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

export const Topbar = ({ className, onSidebarOpen, ...rest }) => {
  const classes = useStyles();
  const [logoutUser, { data, loading }] = useMutation(LOGOUT_MUTATION, {
    errorPolicy: "ignore",
  });

  const [notifications] = useState([]);
  useEffect(() => {
    if (data && data.logoutUser) {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(REST_TOKEN);
      toast("Going home!!");
      setTimeout(() => {
        window.location.replace("/");
      }, 1000);
    }
  }, [data]);
  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/">
          <AppLogo style={{ background: "#ffffff" }} width={150} height={50} />
          {/* <img
            alt="Logo"
            src="/images/logos/ads-logo.svg"
            width={150}
            height={50}
            style={{ background: "#ffffff" }}
          /> */}
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
              overlap="rectangular"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            disabled={loading}
            onClick={() => logoutUser()}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};
