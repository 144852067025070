import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import { useAuth } from "providers/auth";

const useStyles = makeStyles(() => ({
  root: {},
}));

const initialValues = {
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
};
export const AccountDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const { user } = useAuth();

  const [values, setValues] = useState(initialValues);
  const [toEdit, setToEdit] = useState(false);
  useEffect(() => {
    setValues(user);
  }, [user]);
  const handleChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Button variant="text" onClick={() => setToEdit(true)}>
          Edit
        </Button>
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                margin="dense"
                name="firstName"
                onChange={handleChange}
                required={toEdit}
                disabled={!toEdit}
                value={values.firstName}
                variant={toEdit ? "outlined" : "standard"}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="lastName"
                onChange={handleChange}
                required={toEdit}
                disabled={!toEdit}
                value={values.lastName}
                variant={toEdit ? "outlined" : "standard"}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                disabled
                value={values.email}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone"
                onChange={handleChange}
                type="number"
                required={toEdit}
                value={values.phone}
                variant={toEdit ? "outlined" : "standard"}
                disabled={!toEdit}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {toEdit && (
          <CardActions>
            <Button color="primary" variant="contained" disabled>
              Save details
            </Button>
          </CardActions>
        )}
      </form>
    </Card>
  );
};
