import React, { useEffect, useState } from "react";
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { NumericFormat } from "react-number-format";
import { CustomisedTable } from "components/common";
import { calculateTatal, notifier } from "utils/helpers";
import { ADD_BUDGET_ITEMS } from "graphql/mutations/budgets";
import { JSButton } from "components/common/JButton";
import { itemsColumns } from "./columns";

const initialStateItem = { name: "", amount: "", edit: false };

export const CreateOrUpdateItem = ({
  open,
  onClose,
  budget,
  refetch,
  currentRecord,
}) => {
  const [item, setItem] = useState(initialStateItem);
  const [items, setItems] = useState([]);
  const [saveItems, { loading: saving, data, reset }] =
    useMutation(ADD_BUDGET_ITEMS);

  useEffect(() => {
    if (data?.createBudgetItems) {
      onClose();
      refetch();
      setItem(initialStateItem);
      setItems([]);
      setTimeout(() => {
        reset();
      }, 1000);
    }
  }, [data, onClose, reset, refetch]);

  const handleChangeItem = ({ target: { name, value } }) => {
    setItem((prev) => ({ ...prev, [name]: value }));
  };
  const handleAddItem = () => {
    if (!item.name || !item.amount) {
      notifier("Please fill in all the fields");
      return;
    }
    const { name, amount } = item;
    setItems((prev) => {
      const newItems = [...prev];
      const itemIdx = newItems.findIndex((it) => it.name === name);
      if (itemIdx >= 0) {
        newItems[itemIdx].amount = amount;
      } else {
        newItems.push({ name, amount });
      }
      setItem(initialStateItem);
      return newItems;
    });
  };
  const handleItemClick = (currentItem, action) => {
    if (action === "edit") {
      setItem(currentItem);
    }
    if (action === "del") {
      setItems((prev) => prev.filter((it) => it.name !== currentItem.name));
    }
  };
  const handleSave = () => {
    if (items.length === 0) {
      notifier("Please add at least one item");
      return;
    }
    saveItems({
      variables: {
        budgetId: budget?.id,
        items,
      },
    });
  };
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="add-dialog">
        Add or update the items to the Budget:"{budget?.name}"
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill in the required fields
        </DialogContentText>
        <Typography>{items.length} items</Typography>
        <Typography>
          Total budget: <strong>{calculateTatal(items, "amount")}</strong>
        </Typography>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              name="name"
              label="Item name"
              value={item.name}
              onChange={handleChangeItem}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <NumericFormat
              value={item.amount}
              onValueChange={({ floatValue }) =>
                handleChangeItem({
                  target: { name: "amount", value: floatValue },
                })
              }
              //   prefix="RF"
              margin="dense"
              thousandSeparator
              customInput={TextField}
              fullWidth
              variant="outlined"
              label="Amount(RwF)"
            />
          </Grid>
          <Grid item md={1} xs={12}>
            <Button onClick={handleAddItem}>Add</Button>
          </Grid>
        </Grid>
        <CustomisedTable
          data={items}
          columns={itemsColumns(handleItemClick)}
          size="small"
          withPagination={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <JSButton loading={saving} onClick={handleSave} />
      </DialogActions>
    </Dialog>
  );
};
