import { gql } from "@apollo/client";

export const STORE_MUTATION = gql`
  mutation StoreMutation(
    $amount: Float!
    $recordType: String!
    $propertyId: String
    $isInflow: Boolean!
    $isRecurring: Boolean!
    $actionDate: Date!
    $description: String!
    $householdId: String!
    $budgetItemId: String
  ) {
    createStore(
      amount: $amount
      recordType: $recordType
      propertyId: $propertyId
      isInflow: $isInflow
      actionDate: $actionDate
      isRecurring: $isRecurring
      description: $description
      householdId: $householdId
      budgetItemId: $budgetItemId
    ) {
      message
      store {
        id
        amount
        description
        isProperty
        isInflow
        actionDate
      }
    }
  }
`;

export const UPDATE_STORE_MUTATION = gql`
  mutation UpdateStoreMutation(
    $id: String!
    $amount: Float!
    $recordType: String!
    $propertyId: String
    $isInflow: Boolean!
    $actionDate: Date!
    $description: String!
    $householdId: String!
    $budgetItemId: String
  ) {
    updateStore(
      id: $id
      amount: $amount
      recordType: $recordType
      propertyId: $propertyId
      isInflow: $isInflow
      actionDate: $actionDate
      description: $description
      householdId: $householdId
      budgetItemId: $budgetItemId
    ) {
      message
    }
  }
`;
