import React from "react";
import { Typography, Box, Tooltip, IconButton } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { GET_BUDGET_QUERY } from "graphql/queries";
import { useRequestQuery } from "helpers/useRequest";
import { pluralize } from "utils/helpers";
import { CustomisedTable } from "../../components/common/CustomisedTable";
import { itemsColumns } from "./columns";

export const BudgetDetails = ({ budgetId, setCurrentBudget }) => {
  const { results: budget } = useRequestQuery({
    query: GET_BUDGET_QUERY,
    variables: { budgetId },
    dataType: "currentBudget",
  });
  const items = budget?.budgetItems || [];
  return (
    <Box>
      <Box
        display="flex"
        bgcolor="background.paper"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h4">{budget?.name}</Typography>
          <Typography>{pluralize(items.length)}</Typography>
          <Typography>
            Amount spent: <strong>{budget.amountSpent}</strong>
          </Typography>
          <Typography>
            Total budget: <strong>{budget.amount}</strong>
          </Typography>
        </Box>
        {budget?.status !== "APPROVED" && (
          <Box>
            <Tooltip title="Add to list">
              <IconButton
                aria-label="Add to list"
                color="secondary"
                onClick={() =>
                  setCurrentBudget((prev) => ({ ...prev, open: true }))
                }
              >
                <AddIcon /> Add to list
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <CustomisedTable
        data={items}
        columns={itemsColumns()}
        size="small"
        withPagination={false}
      />
    </Box>
  );
};
