import React from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  ButtonGroup,
  Button,
} from "@material-ui/core";

import { getInitials } from "helpers";
import { toDate } from "utils/helpers";
import { Loading, NoDisplayData } from "components";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

export const UsersTable = ({
  className,
  users = [],
  selectedUsers = [],
  usersCount = 0,
  page = 1,
  rowsPerPage = 10,
  pageOptions = [5, 10, 20],
  onEdit,
  loading = false,
  handleSelectOne,
  handlePageChange,
  handleSelectAll,
  handleRowsPerPageChange,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        {loading ? (
          <Loading />
        ) : users.length > 0 ? (
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.length === users.length}
                        color="primary"
                        indeterminate={
                          selectedUsers.length > 0 &&
                          selectedUsers.length < users.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Names</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Staff</TableCell>
                    <TableCell>Last login</TableCell>
                    <TableCell>Registration date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user.id}
                      selected={selectedUsers.indexOf(user.id) !== -1}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUsers.indexOf(user.id) !== -1}
                          color="primary"
                          onChange={(event) => handleSelectOne(event, user.id)}
                          value="true"
                        />
                      </TableCell>
                      <TableCell>
                        <div className={classes.nameContainer}>
                          <Avatar
                            className={classes.avatar}
                            src={user.profilePicture}
                          >
                            {getInitials(user.firstName)}
                          </Avatar>
                          <Typography variant="body1">{`${user.firstName} ${user.lastName}`}</Typography>
                        </div>
                      </TableCell>
                      <TableCell>{user.userName}</TableCell>
                      <TableCell>{user.phone}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <Typography
                          color={user.isActive ? "primary" : "textSecondary"}
                        >
                          {user.isActive ? "Yes" : "No"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color={user.isStaff ? "primary" : "textSecondary"}
                        >
                          {user.isStaff ? "Yes" : "No"}
                        </Typography>
                      </TableCell>
                      <TableCell>{toDate(user.lastLogin)}</TableCell>
                      <TableCell>{toDate(user.createdAt)}</TableCell>
                      <TableCell>
                        <ButtonGroup variant="contained" aria-label="Icons">
                          <Button onClick={() => onEdit(user)}>Edit</Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        ) : (
          <NoDisplayData />
        )}
      </CardContent>
      {users.length > 0 && (
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={usersCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={pageOptions}
          />
        </CardActions>
      )}
    </Card>
  );
};
