import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  CssBaseline,
  Typography,
  Link,
  Paper,
} from '@material-ui/core';
import {
  GitHub as GitHubIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
} from '@material-ui/icons';
import { Header } from './Header';
import { MainFeaturedPost } from './MainFeaturedPost';
import { FeaturedPost } from './FeaturedPost';
import { Footer } from './Footer';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
}));

const mainFeaturedPost = {
  title: 'The Role of Money',
  description:
    "Money has great value, because it can do great good. In the hands of God's children it is food for the hungry, drink for the thirsty, and clothing for the naked.",
  image: 'https://source.unsplash.com/random',
  imgText: 'main image description',
  linkText: 'Continue reading…',
};

const featuredPosts = [
  {
    title: 'George Lorimer',
    date: 'Kan 17',
    description:
      'It’s good to have money and the things that money can buy, but it’s good, too, to check up once in a while and make sure that you haven’t lost the things that money can’t buy.',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
  {
    title: 'Johann Wolfgang von Goethe',
    date: 'Aug 11',
    description:
      'Many people take no care of their money till they come nearly to the end of it, and others do just the same with their time.',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
];

const socials = [
  { name: 'GitHub', icon: <GitHubIcon /> },
  { name: 'Twitter', icon: <TwitterIcon /> },
  { name: 'Facebook', icon: <FacebookIcon /> },
];

export const Main = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth='lg'>
        <Header title='D2D Store' />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post, postIdx) => (
              <FeaturedPost key={postIdx} post={post} />
            ))}
          </Grid>
          <Grid container spacing={5} className={classes.mainGrid}>
            <Grid item md={7} lg={7} sm={6} xs={12}>
              <Typography
                variant='h6'
                gutterBottom
                className={classes.sidebarSection}
              >
                Social
              </Typography>
              {socials.map((network, netIdx) => (
                <Link display='block' variant='body1' href='#' key={netIdx}>
                  <Grid
                    container
                    direction='row'
                    spacing={1}
                    alignItems='center'
                  >
                    <Grid item md={4} lg={4} sm={6} xs={12}>
                      {network.icon}
                      {network.name}
                    </Grid>
                  </Grid>
                </Link>
              ))}
            </Grid>
            <Grid item md={5} lg={5} sm={6} xs={12}>
              <Paper elevation={0} className={classes.sidebarAboutBox}>
                <Typography variant='h6' gutterBottom>
                  About
                </Typography>
                <Typography>
                  Learn to save. Money can be a pretty sweet deal; it can
                  provide you with an exciting lifestyle, glorious things,
                  sometimes a little happiness.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </main>
      </Container>
      <Footer
        title='AaJA'
        description='Save as much money as possible. For sure, you gonna need it tomorrow!'
      />
    </React.Fragment>
  );
};
