import { gql } from "@apollo/client";

export const ADD_BUDGET_ITEMS = gql`
  mutation AddBudgetItems($budgetId: String!, $items: [BudgetItemInputType!]) {
    createBudgetItems(budgetId: $budgetId, items: $items) {
      message
      totalSaved
      totalNotSaved
    }
  }
`;

export const CREATE_BUDGET = gql`
  mutation CreateBudget(
    $name: String!
    $description: String!
    $status: String!
    $startDate: Date!
    $endDate: Date!
    $householdId: String!
  ) {
    createBudget(
      name: $name
      description: $description
      status: $status
      startDate: $startDate
      endDate: $endDate
      householdId: $householdId
    ) {
      message
    }
  }
`;
export const UPDATE_BUDGET = gql`
  mutation UpdateBudget(
    $id: String!
    $name: String
    $description: String
    $status: String
    $startDate: Date
    $endDate: Date
    $householdId: String
  ) {
    updateBudget(
      id: $id
      name: $name
      description: $description
      status: $status
      startDate: $startDate
      endDate: $endDate
      householdId: $householdId
    ) {
      message
    }
  }
`;

export const UPDATE_STORE_MUTATION = gql`
  mutation UpdateStoreMutation(
    $id: String!
    $amount: Float!
    $recordType: String!
    $propertyId: String!
    $isInflow: Boolean!
    $actionDate: Date!
    $description: String!
    $householdId: String!
  ) {
    updateStore(
      id: $id
      amount: $amount
      recordType: $recordType
      propertyId: $propertyId
      isInflow: $isInflow
      actionDate: $actionDate
      description: $description
      householdId: $householdId
    ) {
      message
    }
  }
`;
