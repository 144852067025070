import { ButtonGroup, IconButton } from "@material-ui/core";
import { Edit as EditIcon, Delete as DeleteIcon } from "@material-ui/icons";

const actions = (onItemClick) => [
  {
    content: (item) => (
      <ButtonGroup variant="outlined" size="small">
        <IconButton
          aria-label="Edit"
          color="secondary"
          onClick={() => onItemClick(item, "edit")}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="Edit"
          color="secondary"
          onClick={() => onItemClick(item, "del")}
        >
          <DeleteIcon />
        </IconButton>
      </ButtonGroup>
    ),
    label: "Actions",
  },
];
export const itemsColumns = (onItemClick) => [
  {
    path: "name",
    label: "Name",
  },
  {
    path: "amount",
    label: "Amount",
  },
  {
    path: "amountSpent",
    label: "Amount spent",
  },
  ...(onItemClick ? actions(onItemClick) : []),
];
