import React from "react";
import {
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { EditRounded as EditIcon } from "@material-ui/icons";

export const transactionColumns = (onTransactionClick) => [
  { path: "amount", label: "Amount" },
  { path: "description", label: "Description" },
  {
    content: (item) => (
      <Typography color={item.isInflow ? "primary" : "textSecondary"}>
        {item.isInflow ? "Yes" : "No"}
      </Typography>
    ),
    label: "Income",
  },
  {
    content: (item) => (
      <Typography color={item.property ? "primary" : "textSecondary"}>
        {item.property?.name || "-"}
      </Typography>
    ),
    label: "Property",
  },
  {
    content: (item) => (
      <Typography color={item.budgetItem ? "primary" : "textSecondary"}>
        {item.budgetItem?.name || "-"}
      </Typography>
    ),
    label: "Budget detail",
  },
  { path: "actionDate", label: "Registered on" },
  {
    content: (item) => (
      <ButtonGroup variant="outlined" size="small">
        <Tooltip title="Edit">
          <IconButton
            aria-label="Edit"
            color="secondary"
            onClick={() => onTransactionClick(item)}
          >
            <EditIcon /> Edit
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    ),
    label: "Actions",
  },
];
