import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  Grid,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { D2DAutocomplete } from "components/common";
import { householdAccessLevels } from "utils/helpers";
import { autoCompleteOption } from "utils/initialStates";

export const AddUser = ({
  open,
  setOpen,
  values = {},
  setValues,
  onSave,
  loading = false,
  households = [],
}) => {
  const onHandleChange =
    (hasCheck = false) =>
    ({ target: { name, value, checked } }) => {
      setValues((prev) => ({
        ...prev,
        [name]: hasCheck ? checked : value,
      }));
    };
  const onHouseholdChange = (field, hhIndex) => (_, value) => {
    const householdsCopy = [...values.households];
    const currentHousehold = householdsCopy[hhIndex];
    const updated = { ...currentHousehold, [field]: value };
    householdsCopy[hhIndex] = updated;
    setValues((prev) => ({ ...prev, households: householdsCopy }));
  };
  const handleAddRemove = (hhIndex = null, op = "+") => {
    const householdsCopy = [...values.households];
    if (op === "+") {
      householdsCopy.push({
        household: autoCompleteOption,
        accessLevel: autoCompleteOption,
      });
    } else if (hhIndex) {
      householdsCopy.splice(hhIndex, 1);
    }
    setValues((prev) => ({ ...prev, households: householdsCopy }));
  };
  return (
    <Dialog
      onClose={setOpen}
      aria-labelledby="store-import"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="store-import" onClose={setOpen}>
        User recording
        <FormControlLabel
          control={
            <Switch
              name="configureHousehold"
              checked={values.configureHousehold}
              onChange={onHandleChange(true)}
            />
          }
          label="Configure household?"
          style={{ position: "absolute", right: 8, top: 8 }}
        />
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {values.id ? "Update the user" : "To add a new user."}
        </DialogContentText>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              name="email"
              label="Email"
              value={values.email}
              onChange={onHandleChange()}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              name="phone"
              label="Phone number"
              value={values.phone}
              onChange={onHandleChange()}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              name="userName"
              label="User name"
              value={values.userName}
              onChange={onHandleChange()}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              label={values.id ? "Change the current" : "User password"}
              name="password"
              value={values.password}
              onChange={onHandleChange()}
            />
          </Grid>
          {values.id &&
            values.households?.map((hh, hhIndex) => (
              <React.Fragment key={hhIndex}>
                <Grid item md={5} xs={12}>
                  <D2DAutocomplete
                    id="household-select"
                    getOptionLabel={(op) => op.name}
                    name="household"
                    value={hh.household}
                    label="Select a household"
                    onChange={onHouseholdChange("household", hhIndex)}
                    options={households}
                  />
                </Grid>
                <Grid item md={5} xs={12}>
                  <D2DAutocomplete
                    id="access-level-select"
                    getOptionLabel={(op) => op.name}
                    name="accessLevel"
                    value={hh.accessLevel}
                    label="Set member access"
                    onChange={onHouseholdChange("accessLevel", hhIndex)}
                    options={householdAccessLevels}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  {hhIndex === 0 ? (
                    <Button onClick={() => handleAddRemove()}>Add</Button>
                  ) : (
                    <Button onClick={() => handleAddRemove(hhIndex, "-")}>
                      Remove
                    </Button>
                  )}
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onSave} color="primary">
          {loading ? "User saving,..." : "Save user"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
