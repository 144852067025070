import { useEffect, useState } from "react";
import { client } from "graphql/client";
import { useQuery } from "@apollo/client";

export const useRequestQuery = ({
  query = "",
  initialData = {},
  variables = {},
  dataType = "",
}) => {
  const [resultsData, setResultsData] = useState(initialData);

  const { data, error, loading } = useQuery(query, {
    variables,
    errorPolicy: "all",
  });

  useEffect(() => {
    if (data && data[dataType]) {
      setResultsData(data[dataType]);
    }
  }, [data, dataType]);

  return { results: resultsData, error, loading };
};

export const useRequestMutation = ({
  query = "",
  initialData = { error: null, data: null, isLoading: false, success: false },
  variables = {},
  dataType = "",
}) => {
  const [resultsData, setResultsData] = useState(initialData);
  // const [error, setError] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (query) {
      client
        .mutate({ query, variables, errorPolicy: "all" })
        .then(({ data, loading, errors }) => {
          setResultsData({ isLoading: loading });
          if (data && !errors && data[dataType]) {
            setResultsData({ success: true });
          }
          if (errors) {
            console.log({ errors });
            setResultsData({ error: "There has been an error" });
          }
        })
        .catch(({ message }) => {
          setResultsData({ error: message });
        });
    }
  }, [dataType, query, variables]);

  return resultsData;
};
