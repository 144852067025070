import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      message
      token
      restToken
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation LogoutMutation {
    logoutUser {
      message
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPasswordMutation(
    $email: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    resetPassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      message
    }
  }
`;
