import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import validate from "validate.js";
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// import { Facebook as FacebookIcon, Google as GoogleIcon } from "icons";
import { useAuthStyles } from "utils/authStyles";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "graphql/mutations/auth";
import { AUTH_TOKEN, REST_TOKEN } from "utils/constants";
import { toast } from "react-toastify";
import { useAuth } from "providers/auth";
import { ResetPassword } from "components";
import { redirectToHome } from "utils/helpers";

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
};

export const SignIn = ({ history }) => {
  const classes = useAuthStyles();
  const [openReset, setOpenReset] = useState(false);
  const [loginUser, { data, loading, error }] = useMutation(LOGIN_MUTATION, {
    errorPolicy: "all",
  });
  const { isAuthenticated, ...rest } = useAuth();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    if (isAuthenticated) {
      redirectToHome();
    }
  }, [isAuthenticated]);
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);
  useEffect(() => {
    if (data?.loginUser) {
      const {
        loginUser: { token, restToken, user },
      } = data;
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(REST_TOKEN, restToken);
      toast(`Welcome ${user.firstName} ${user.lastName}!!`);
      setTimeout(() => {
        redirectToHome();
      }, 5000);
    }
  }, [data]);
  useEffect(() => {
    if (error?.message === "Create a new password first") {
      setTimeout(() => {
        setOpenReset(true);
      }, 3000);
    }
  }, [error]);
  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  console.log({ rest });

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                D a y T o D a y T r a n s a c t i o n
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="body1">
                  D2D Store.
                </Typography>
                <Typography className={classes.bio} variant="body2">
                  Transanction
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form className={classes.form}>
                <Typography className={classes.title} variant="h2">
                  Sign in
                </Typography>
                {/* <Typography color='textSecondary' gutterBottom>
                  Sign in with social media
                </Typography> */}
                {/* <Grid className={classes.socialButtons} container spacing={2}>
                  <Grid item>
                    <Button color='primary' size='large' variant='contained'>
                      <FacebookIcon className={classes.socialIcon} />
                      Login with Facebook
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button size='large' variant='contained'>
                      <GoogleIcon className={classes.socialIcon} />
                      Login with Google
                    </Button>
                  </Grid>
                </Grid> */}
                {/* <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1"
                >
                  or login with email address
                </Typography> */}
                <TextField
                  className={classes.textField}
                  error={hasError("email")}
                  fullWidth
                  helperText={
                    hasError("email") ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ""}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError("password")}
                  fullWidth
                  helperText={
                    hasError("password") ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ""}
                  variant="outlined"
                />

                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!formState.isValid || loading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => loginUser({ variables: formState.values })}
                >
                  {loading ? "Sign in,..." : "Sign in now"}
                </Button>
                {/* <Typography color="textSecondary" variant="body1">
                  Don't have an account?{" "}
                  <Link component={RouterLink} to="/sign-up" variant="h6">
                    Sign up
                  </Link>
                </Typography> */}
              </form>
              <Dialog open={openReset} onClose={() => setOpenReset()}>
                <DialogTitle>Create a new password</DialogTitle>
                <DialogContent>
                  <ResetPassword
                    title="Create a new password"
                    userInfo={formState.values}
                  />
                </DialogContent>
                <DialogActions>
                  <Button color="primary" onClick={() => setOpenReset()}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default withRouter(SignIn);
