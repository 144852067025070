import { Typography } from "@material-ui/core";

export const prodDetailColumns = [
  { path: "description", label: "Description" },
  {
    content: (item) => (
      <Typography color={item.isInflow ? "primary" : "textSecondary"}>
        {item.isInflow ? "Inflow" : "Outflow"}
      </Typography>
    ),
    label: "Property",
  },
  { path: "amount", label: "Amount" },
];
