import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TotalTransations } from './TotalTransations';
import { PropertiesView } from './PropertiesView';
import { StoreDetail } from './StoreDetail';
import { StoreBalance } from './StoreBalance';

export const DashboardCounts = ({ loading = false, dashboardData = {} }) => {
  return (
    <Grid container spacing={2}>
      {loading ? (
        Array(4).map((item, idx) => (
          <Grid item lg={3} sm={6} xl={3} xs={12} key={idx}>
            <Skeleton variant='rect' width='100%' height='100%' />
          </Grid>
        ))
      ) : (
        <>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalTransations storeCount={dashboardData.storeCount} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <PropertiesView propertiesDetail={dashboardData.propertiesDetail} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <StoreDetail
              totalInflow={dashboardData.totalInflow}
              totalOutflow={dashboardData.totalOutflow}
            />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <StoreBalance
              balance={dashboardData.totalInflow - dashboardData.totalOutflow}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
