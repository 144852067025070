import moment from "moment";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Custom hook to manage search parameters in the URL
export const useStoreSearchParams = () => {
  const [searchParams, setSearchParams] = useState({
    pageNumber: 1,
    pageCount: 10,
    searchKey: "",
    searchDateFrom: moment().startOf("year").format("YYYY-MM-DD"),
    searchDateTo: moment().format("YYYY-MM-DD"),
  });

  const history = useHistory();
  const location = useLocation();

  // Sync state with URL when the component loads or the URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const pageNumber = params.get("page") || 1;
    const pageCount = params.get("item_per_page") || 10;
    const searchKey = params.get("q") || "";
    const searchDateFrom =
      params.get("start_date") || moment().startOf("year").format("YYYY-MM-DD");
    const searchDateTo =
      params.get("end_date") || moment().format("YYYY-MM-DD");

    setSearchParams({
      pageNumber: parseInt(pageNumber, 10),
      pageCount: parseInt(pageCount, 10),
      searchKey,
      searchDateFrom,
      searchDateTo,
    });
  }, [location.search]);

  // Update the URL with new search parameters
  const updateURL = (newParams) => {
    const params = new URLSearchParams();

    if (newParams.pageNumber) params.set("page", newParams.pageNumber);
    if (newParams.pageCount) params.set("item_per_page", newParams.pageCount);
    if (newParams.searchKey) params.set("q", newParams.searchKey);
    if (newParams.searchDateFrom)
      params.set("start_date", newParams.searchDateFrom);
    if (newParams.searchDateTo) params.set("end_date", newParams.searchDateTo);

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  // Handle setting new parameters
  const setSearchParam = (keyValuePair) => {
    const updatedParams = { ...searchParams };
    Object.entries(keyValuePair).forEach(([key, value]) => {
      updatedParams[key] = value;
    });
    setSearchParams(updatedParams);
    updateURL(updatedParams);
  };

  return [searchParams, setSearchParam];
};
