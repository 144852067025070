import { makeStyles } from "@material-ui/styles";

export const useLayoutStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: "calc(100% - 64px)",
    marginTop: 64,
  },
}));
