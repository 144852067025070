export const salaryColumns = [
  { field: "pension", headerName: "Pension", width: 120 },
  { field: "maternity", headerName: "Maternity", width: 150 },
  { field: "tax", headerName: "Tax", width: 100 },
  { field: "netSalary", headerName: "Net salary", width: 150 },
  { field: "netPay", headerName: "Net pay", width: 120 },
  { field: "grossSalary", headerName: "Gross salary", width: 150 },
];

export const salaryInitialState = {
  pension: "",
  maternity: "",
  tax: "",
  netPay: "",
  netSalary: "",
  grossSalary: "",
};
export const salaryState = {
  id: 0,
  grossSalary: 0,
  maternity: 0,
  netPay: 0,
  netSalary: 0,
  pension: 0,
  tax: 0,
  facilities: [],
};
export const selectFields = [
  { id: "ns", name: "Net salary" },
  { id: "gs", name: "Gross salary" },
];
export const toFloatValues = (values = {}) => {
  let result = {};
  for (let [key, value] of Object.entries(values)) {
    let newKeyValue = 0;
    if (!Number.isNaN(parseFloat(value))) {
      newKeyValue = parseFloat(value);
    }
    result[key] = newKeyValue;
  }
  return result;
};
