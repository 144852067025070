import { useMutation, useQuery } from "@apollo/client";
import { budgetColumns } from "components/columns";
import { AlertConfirm, CustomisedTable } from "components/common";
import { GET_BUDGETS_QUERY } from "graphql/queries";
import { useEffect, useState } from "react";
import { initialBudgetFilters } from "utils/helpers";
import { transactionStyles } from "views/Transactions/transactionStyles";
import { CreateOrUpdateItem } from "./CreateOrUpdateItem";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { CreateOrUpdateBudget } from "./CreateOrUpdateBudget";
import { UPDATE_BUDGET } from "graphql/mutations/budgets";
import { BudgetDetails } from "./BudgetDetails";

const initialCurrentState = {
  current: null,
  open: false,
  openApprove: false,
  message: "Are you sure you want to approve",
};

export const BudgetView = () => {
  const classes = transactionStyles();
  const [filters] = useState(initialBudgetFilters);
  const [currentBudget, setCurrentBudget] = useState(initialCurrentState);
  const [openCreate, setOpenCreate] = useState(false);
  const { loading, data, refetch } = useQuery(GET_BUDGETS_QUERY, {
    variables: filters,
  });
  const [updateBudget, { loading: updating, data: updData, reset: updReset }] =
    useMutation(UPDATE_BUDGET);

  useEffect(() => {
    const budgets = data?.budgets?.pageData || [];
    if (budgets.length) {
      setCurrentBudget((prev) => ({ ...prev, current: budgets[0] }));
    }
  }, [data?.budgets]);

  useEffect(() => {
    if (updData?.updateBudget) {
      setCurrentBudget(initialCurrentState);
      refetch();
      setTimeout(() => {
        updReset();
      }, 1000);
    }
  }, [updData, refetch, updReset]);

  const handleBudgetClick = (budget, action) => {
    setCurrentBudget((prev) => {
      const currentState = { ...prev, current: budget };
      if (action === "addItems" && budget.status !== "APPROVED") {
        currentState.open = true;
      }
      if (action === "approve") {
        currentState.message =
          initialCurrentState.message + " the budget:" + budget.name;
        currentState.openApprove = true;
      }
      return currentState;
    });
  };
  const handleConfirmYes = () => {
    updateBudget({
      variables: { id: currentBudget.current?.id, status: "approved" },
    });
  };
  const budgets = data?.budgets ?? {};
  return (
    <div className={classes.root}>
      <div className={classes.row}></div>
      <div className={classes.content}>
        <AlertConfirm
          {...currentBudget}
          open={currentBudget.openApprove}
          setOpen={() =>
            setCurrentBudget((prev) => ({ ...prev, openApprove: false }))
          }
          onConfirmYes={handleConfirmYes}
          loading={updating}
        />
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h4">
                Budgets({budgets.pageData?.length})
              </Typography>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setOpenCreate(true)}
              >
                Add a new budget
              </Button>
            </Box>
            <CustomisedTable
              title={`Budgets(${budgets.pageData?.length})`}
              data={budgets.pageData}
              dataCount={budgets.numPages}
              //   page={filters.pageNumber}
              columns={budgetColumns(handleBudgetClick)}
              loading={loading}
              size="small"
              withPagination={false}
              //   rowsPerPage={filters.pageCount}
              pageOptions={[5, 10, 25, 50, 100]}
              //   handlePageChange={handlePageChange}
              //   handleRowsPerPageChange={handleRowsPerPageChange}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            {currentBudget.current && (
              <BudgetDetails
                budgetId={currentBudget.current?.id}
                setCurrentBudget={setCurrentBudget}
              />
            )}
          </Grid>
        </Grid>
      </div>
      {currentBudget.current && (
        <>
          <CreateOrUpdateItem
            open={currentBudget.open}
            budget={currentBudget.current}
            onClose={() => setCurrentBudget(initialCurrentState)}
            refetch={refetch}
          />
          <CreateOrUpdateBudget
            open={openCreate}
            budget={currentBudget.current}
            onClose={() => setOpenCreate(false)}
            refetch={refetch}
          />
        </>
      )}
    </div>
  );
};
