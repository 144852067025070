import axios from 'axios';
import { REST_TOKEN } from './constants';

let token = localStorage[REST_TOKEN];

export const http = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}/api`,
	//   withCredentials: true,
	headers: {
		authorization: token ? `Token ${token}` : ''
	}
});
