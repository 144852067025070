import moment from "moment";

export const autoCompleteOption = {
  id: "",
  name: "",
};
export const dashboardInitials = {
  totalInflow: 0,
  totalOutflow: 0,
  storeCount: 0,
  storeAggregate: {
    inflow: 0,
    outflow: 0,
    percent: 0,
  },
  propertiesDetail: {
    count: 0,
    totalAmount: 0,
  },
};
// const date = new Date();
// const today = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
export const recordInitials = {
  amount: "",
  recordType: { id: "use", name: "Store" },
  property: autoCompleteOption,
  isInflow: false,
  isRecurring: false,
  actionDate: moment().format("YYYY-MM-DD"),
  description: "",
  household: autoCompleteOption,
  budgetItem: autoCompleteOption,
};
export const productInitials = {
  name: "",
  description: "",
  price: "",
  coverImage: "",
  isActive: true,
};
export const prodDetailInitials = {
  title: "",
  amount: "",
  isInflow: true,
};
export const userInitials = {
  id: null,
  email: "",
  userName: "",
  password: "",
  phone: "",
  configureHousehold: false,
  households: [
    { household: autoCompleteOption, accessLevel: autoCompleteOption },
  ],
};
